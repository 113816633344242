import React from "react";
import { Icon } from "semantic-ui-react";

export default ({ slot, index, size }) => {
  // const state = useSelector(state => state.state)
  return (
    <div style={{padding: "5px"}} className={`box${index}`}>
      {slot.state!=="Empty" ? (
        <Icon
          color={slot.state==="Ready" ? "green" : "grey"}
          size={size||"huge"}
          name={slot.state=== "Ready" ? "battery full" : "battery half"}
        />
      ) : ( 
        <Icon color="yellow" size={size||"huge"} name="cancel" />
      )}
    </div>
  );
};
