import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Grid,
  GridRow,
  Loader,
  GridColumn,
  Button,
  Divider,
  Icon,
  Container
} from "semantic-ui-react";
import BatteryImage from "../components/BatteryImage";
import { fetchSwapOrderBySpName } from "../store/swapOrderSlice";
import { setLoading } from "../store/appState";
import "../assets/styles/swaporder.scss";
import Box from "../components/Box";
import PostOrderModal from "../components/PostOrderModal";
import { showPostOrderModal } from "../store/appState";
import { parse } from "qs";
import "../style.css";
import { navigate } from "@reach/router";
import { API, graphqlOperation } from "aws-amplify";
import { onEntryChange } from "../graphql/subscriptions";

const SwapOrder = ({ location }) => {
  const [isOpenLoading, setOpenLoading] = useState(false);
  const [isCompletionLoading, setIsCompletionLoading] = useState(false);
  const [batteryIn, setBatteryIn] = useState(0);
  const [batteryOut, setBatteryOut] = useState(0);
  const [boxes, setBoxes] = useState([]);

  let { mySwapOrder, isLoading } = useSelector(state => state.swapOrder);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchSwapOrderBySpName(
        parse(location.search, { ignoreQueryPrefix: true }).token
      )
    );
  }, []);

  useEffect(() => {
    API.graphql(graphqlOperation(onEntryChange)).subscribe({
      next: ({ provider, value }) => {
        if (
          value.data.entryChange.source !== "SHARINGPOINT_CHANGED" &&
          value.data.entryChange.source === "ORDER_CHANGED" &&
          value.data.entryChange.id ===
            parse(location.search, { ignoreQueryPrefix: true }).token
        )
          dispatch(
            fetchSwapOrderBySpName(
              parse(location.search, { ignoreQueryPrefix: true }).token
            )
          );
      }
    });
  }, []);

  useEffect(() => {
    if (mySwapOrder) {
      const locked = Object.values(JSON.parse(mySwapOrder.swap_status_map)).map(
        m => m === "locked" || m === "door_error"
      );

      let temp = mySwapOrder.box_status.boxes.map(b => b);

      setBoxes(temp.sort((a, b) => a.box_id - b.box_id));

      if (
        (mySwapOrder.swap_state === "LESS_TAKEN_THAN_ORDERED" &&
          locked.every(a => a === true) &&
          ["Okai A", "Okai B", "Tier_dev", "Segway"].includes(
            mySwapOrder.battery_type
          )) ||
        (mySwapOrder.swap_state === "MORE_TAKEN_THAN_ORDERED" &&
          locked.every(a => a === true) &&
          ["Okai A", "Okai B", "Tier_dev", "Segway"].includes(
            mySwapOrder.battery_type
          ))
      ) {
        dispatch(showPostOrderModal());
        setIsCompletionLoading(false);
        setOpenLoading(false);
      } else if (
        mySwapOrder.swap_state === "COMPLETED" ||
        mySwapOrder.swap_state === "ABORTED"
      ) {
        return;
      }
    }
  }, [mySwapOrder]);

  return (
    <Container style={{ width: "100vh" }}>
      {mySwapOrder && (
        <Grid
          verticalAlign="middle"
          className="swaporder-status-container"
          style={{ margin: "auto", height: "100%" }}
        >
          {mySwapOrder && (
            <PostOrderModal
              setBatteryIn={setBatteryIn}
              setBatteryOut={setBatteryOut}
              batteryIn={batteryIn}
              batteryOut={batteryOut}
              token={mySwapOrder.confirmation_token}
              setOpenLoading={setOpenLoading}
              isOpenLoading={isOpenLoading}
              isCompletionLoading={isCompletionLoading}
              setIsCompletionLoading={setIsCompletionLoading}
            />
          )}
          {isLoading && !mySwapOrder ? (
            <Loader
              style={{ position: "fixed", top: "400px" }}
              active
              inverted
            />
          ) : (
            <>
              <GridColumn width="16" textAlign="center">
                <h3 style={{ color: "white" }}>
                  {mySwapOrder.sharing_point_name.substr(0, 13)}
                  {mySwapOrder.sharing_point_name.length > 13 && "..."}
                </h3>
              </GridColumn>

              <GridColumn width="16" textAlign="center">
                <BatteryImage
                  width={"auto"}
                  height={200}
                  type={mySwapOrder.battery_type.replaceAll("_", "")}
                />
              </GridColumn>

              <GridColumn mobile={6}>
                <span className="swaporder-label">STATE</span>
              </GridColumn>
              <GridColumn mobile={10}>
                <span className="swaporder-value">
                  {mySwapOrder.swap_state === "OPEN" ? (
                    <div>
                      <Loader inline active /> OPEN
                    </div>
                  ) : (
                    <span>
                      <div>
                        {mySwapOrder.swap_state === "COMPLETED" ? (
                          <Icon color="green" name="check circle" />
                        ) : (
                          <div>
                            <Icon color="yellow" name="warning circle" />
                            {mySwapOrder.swap_state === "ABORTED" && (
                              <span>ABORTED</span>
                            )}
                          </div>
                        )}
                      </div>
                    </span>
                  )}
                </span>
              </GridColumn>
              <GridColumn mobile={8} verticalAlign="top" textAlign="left">
                <span className="swaporder-label">TYPE</span>
              </GridColumn>
              <GridColumn mobile={8} textAlign="right" verticalAlign="top">
                <span className="swaporder-value">
                  {mySwapOrder.battery_type === "Tier_dev"
                    ? "Okai A"
                    : mySwapOrder.battery_type}
                </span>
              </GridColumn>

              {mySwapOrder.battery_count !== -1 && (
                <>
                  <GridColumn mobile={8}>
                    <span className="swaporder-label">ORDERED</span>
                  </GridColumn>
                  <GridColumn mobile={8}>
                    <span className="swaporder-value">
                      {mySwapOrder.battery_count}
                    </span>
                  </GridColumn>
                </>
              )}

              {mySwapOrder.box_status &&
                ["Okai A", "Okai B", "Tier_dev", "Segway"].includes(
                  mySwapOrder.battery_type
                ) && (
                  <>
                    <GridRow>
                      <GridColumn mobile={10}>
                        <span className="swaporder-label">SWAP COUNT</span>
                      </GridColumn>
                      <GridColumn mobile={6}>
                        <span className="swaporder-value">
                          {mySwapOrder.box_status.global_box_in} /{" "}
                          {mySwapOrder.box_status.global_box_out}
                        </span>
                      </GridColumn>
                    </GridRow>
                  </>
                )}

              {/* {!["Okai A", "Okai B", "Tier_dev", "Segway"].includes(
                mySwapOrder.battery_type
              ) &&
                Object.keys(JSON.parse(mySwapOrder.swap_status_map)).map(
                  (key, index) => {
                    return (
                      key !== "-1" &&
                      key !== "-1" && (
                        <GridRow key={index}>
                          <GridColumn mobile={10}>
                            <span className="swaporder-label">BOX NUMBER</span>
                          </GridColumn>
                          <GridColumn mobile={6}>
                            <span className="swaporder-value">{key}</span>
                          </GridColumn>
                          <GridColumn style={{ marginTop: "30px" }} mobile={10}>
                            <span className="swaporder-label">DOOR STATE</span>
                          </GridColumn>
                          <GridColumn style={{ marginTop: "30px" }} mobile={6}>
                            <span className="swaporder-value">
                              <Icon
                                size="small"
                                color={
                                  JSON.parse(mySwapOrder.swap_status_map)[
                                    key
                                  ] === "locked"
                                    ? "green"
                                    : "yellow"
                                }
                                name={
                                  JSON.parse(mySwapOrder.swap_status_map)[
                                    key
                                  ] === "locked"
                                    ? "lock"
                                    : "lock open"
                                }
                              />
                            </span>
                          </GridColumn>
                          <Divider style={{ width: "100%" }} />
                        </GridRow>
                      )
                    );
                  }
                )} */}

              {mySwapOrder.box_status &&
                boxes.map((box, index) => (
                  <GridRow style={{ marginTop: "10px" }} key={index} centered>
                    <Divider style={{ width: "100%" }} />

                    <GridColumn mobile={10}>
                      <span className="swaporder-label">BOX NUMBER</span>
                    </GridColumn>
                    <GridColumn mobile={6}>
                      <span className="swaporder-value">{box.box_id}</span>
                    </GridColumn>

                    <GridColumn style={{ marginTop: "30px" }} mobile={10}>
                      <span className="swaporder-label">IN/OUT</span>
                    </GridColumn>
                    <GridColumn style={{ marginTop: "30px" }} mobile={6}>
                      <span className="swaporder-value">{`${box.box_in}/${box.box_out}`}</span>
                    </GridColumn>

                    <GridRow style={{ marginTop: "20px" }}>
                      <Grid>
                        <GridRow>
                          <GridColumn
                            textAlign="center"
                            verticalAlign="middle"
                            width="4"
                          >
                            <Icon
                              size="big"
                              color={
                                JSON.parse(mySwapOrder.swap_status_map)[
                                  box.box_id
                                ] === "locked"
                                  ? "green"
                                  : "yellow"
                              }
                              name={
                                JSON.parse(mySwapOrder.swap_status_map)[
                                  box.box_id
                                ] === "locked"
                                  ? "lock"
                                  : "lock open"
                              }
                            />
                          </GridColumn>
                          <GridColumn width="12">
                            <Box
                              battery_type={mySwapOrder.battery_type}
                              key={index}
                              slots={box.slots}
                            />
                          </GridColumn>
                        </GridRow>
                      </Grid>
                    </GridRow>
                  </GridRow>
                ))}

              {!mySwapOrder.box_status.boxes.length &&
                ["Okai A", "Okai B"].includes(mySwapOrder.battery_type) && (
                  <GridRow centered columns="3">
                    <Divider />
                    <Loader size="huge" inline active />
                  </GridRow>
                )}

              <GridRow>
                {["COMPLETED", "ABORTED"].includes(mySwapOrder.swap_state) && (
                  <Button
                    style={{ width: "100%", color: "white" }}
                    circular
                    size="huge"
                    onClick={() => navigate("/")}
                  >
                    HOME
                  </Button>
                )}
              </GridRow>
            </>
          )}
        </Grid>
      )}
    </Container>
  );
};

const mapDispatch = { fetchSwapOrderBySpName, setLoading };
export default connect(null, mapDispatch)(SwapOrder);
