import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { navigate } from "@reach/router";
import { resetValidation } from "../store/validationSlice";
import { useDispatch } from "react-redux";

const ScanQrCodeButton = () => {
  const dispatch = useDispatch()
  return (
      <Button
        className="button-over-map"
        size="massive"
        onClick={()=> {
          dispatch(resetValidation())
          navigate("/scan")
        }}
        color="black"
        circular
        icon="qrcode"
      >
      </Button>
  );
};

export default ScanQrCodeButton;
