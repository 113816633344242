import React, { useEffect, useState } from "react";
import {
  Modal,
  Container,
  TransitionablePortal,
  Button,
  GridRow,
  Grid,
  Icon,
  Divider
} from "semantic-ui-react";
import { useSelector, connect, useDispatch } from "react-redux";
import "../modal.responsiv.scss";
import "../modal.scss";
import { hidePostOrderModal } from "../store/appState";
import { cancel_order, confirm_open_door } from "../store/swapOrderSlice";
import AppLoader from "./AppLoader";

const PostOrderModal = ({
  token,
  isCompletionLoading,
  setIsCompletionLoading,
  isOpenLoading,
  setOpenLoading
}) => {
  const dispatch = useDispatch();
  const { isPostOrderModal } = useSelector(state => state.appState);
  const { isLoading, swap_state, box_status, battery_count } = useSelector(
    state => state.swapOrder.mySwapOrder
  );

  const handleOpenDoorConfirmation = () => {
    dispatch(confirm_open_door(token));
    setOpenLoading(true);
  };

  const handleOrderCancelation = () => {
    dispatch(cancel_order(token));
    setIsCompletionLoading(true);
  };

  useEffect(() => {
    if (
      swap_state === "OPEN" ||
      swap_state === "ABORTED" ||
      swap_state === "COMPLETED"
    ) {
      dispatch(hidePostOrderModal());
    }
  });

  const states = {
    LESS_TAKEN_THAN_ORDERED: `Less batteries taken`,
    MORE_TAKEN_THAN_ORDERED: `More batteries taken`,
    MORE_RETURNED_THAN_ORDERED: `More batteries returned`,
    LESS_RETURNED_THAN_ORDERED: `Less batteries returned`,
    COMPLETED:
      "Your swap was successfull. Thank you for using Swobbee. We hope to see you again soon."
  };

  const build_message = (global_box_in, global_box_out, battery_count) => {
    let message = "";
    if (global_box_in > battery_count) {
      message += states.MORE_RETURNED_THAN_ORDERED;
    } else if (global_box_in < battery_count) {
      message += states.LESS_RETURNED_THAN_ORDERED;
    }
    if (message.length) message += " and ";
    if (global_box_out > battery_count) {
      message += states.MORE_TAKEN_THAN_ORDERED;
    } else if (global_box_out < battery_count) {
      message += states.LESS_TAKEN_THAN_ORDERED;
    }
    return message + " than orderd.";
  };

  return (
    <Container>
      {isPostOrderModal && (
        <TransitionablePortal
          open={isPostOrderModal}
          transition={{ animation: "scale", duration: 500 }}
        >
          <Modal
            centered={true}
            className="bt-sl-mdl"
            open={isPostOrderModal}
            onClose={() => {
              // dispatch(hidePostOrderModal());
            }}
          >
            <Modal.Header>Warning</Modal.Header>
            <Modal.Content>
              <Grid centered style={{ padding: "30px" }}>
                <GridRow>
                  {!isLoading ? (
                    <Icon
                      color={swap_state !== "COMPLETED" ? "yellow" : "green"}
                      size="massive"
                      name={
                        swap_state === "COMPLETED"
                          ? "check circle"
                          : "warning sign"
                      }
                    />
                  ) : (
                    <AppLoader />
                  )}
                </GridRow>

                {(swap_state === "LESS_TAKEN_THAN_ORDERED" ||
                  swap_state === "MORE_TAKEN_THAN_ORDERED") && (
                  <>
                    <GridRow
                      style={{
                        padding: "0px 20px 0px 20px",
                        width: "95%",
                        fontSize: "20px",
                        marginTop: "20px"
                      }}
                    >
                      <strong>
                        {build_message(
                          box_status.global_box_in,
                          box_status.global_box_out,
                          battery_count
                        )}
                      </strong>

                      <br />
                      <strong>
                        Please press reopen to open doors and correct swap or
                        complete to force completion
                      </strong>
                    </GridRow>
                    <Divider />
                    <GridRow>
                      <Button
                        size="huge"
                        style={{ width: "100%" }}
                        circular
                        onClick={handleOpenDoorConfirmation}
                        positive
                        loading={isOpenLoading}
                      >
                        <Icon name="lock open" />
                        REOPEN{" "}
                      </Button>
                    </GridRow>
                    <GridRow>
                      <Button
                        size="huge"
                        style={{ width: "100%" }}
                        circular
                        onClick={handleOrderCancelation}
                        primary
                        loading={isCompletionLoading}
                      >
                        <Icon name="checkmark" />
                        FORCE COMPLETION{" "}
                      </Button>
                    </GridRow>
                  </>
                )}
              </Grid>
            </Modal.Content>
          </Modal>
        </TransitionablePortal>
      )}
    </Container>
  );
};

const mapDispatch = { hidePostOrderModal, confirm_open_door, cancel_order };
export default connect(null, mapDispatch)(PostOrderModal);
