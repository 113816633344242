import React, { useState } from "react";
import {
  Button,
  Form,
  Header,
  Grid,
  Container,
  Label
} from "semantic-ui-react";
import "./style.scss";
import Submit from "./Submit";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { navigate } from "@reach/router";
import awsmobile from "../../aws-exports/aws-exports-rel";

const defaultValues = {
  email: ""
};
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
});

const SignUp = props => {
  const { handleSubmit, errors, register } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues
  });

  const [state, setstate] = useState({});
  const [email, setEmail] = useState();
  const requestNewPassword = async username => {
    const url = awsmobile.FLASK_REST_API + "/forgot_password";
    axios
      .post(url, {
        username,
        client_id: awsmobile.aws_user_pools_web_client_id
      })
      .then(result => {
        setstate({ ...state, showSubmissionView: true });
        setEmail(username);
      })
      .catch(error => {
        console.log( error);
      });
  };

  const submit = data => {
    requestNewPassword(data.email);
  };

  const onClickHandler = event => {
    event.preventDefault();
    navigate("/");
  };

  return (
    <>
      {!state.showSubmissionView && (
        <Container style={{ marginTop: "100px", padding: "35px" }}>
          <Form onSubmit={handleSubmit(submit)}>
            <Grid>
              <Grid.Row>
                <Header style={{color: "black"}} >Change Password</Header>
              </Grid.Row>
              
                <label style={{color: "black",}} id="signup-label">Email</label>
              
              <Grid.Row>
                
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  ref={register}
                />
                {errors.email && (
                  <Label pointing basic color="red">
                    This field is required
                  </Label>
                )}
              </Grid.Row>
              <Button
                primary
                type="submit"
                style={{ position: "fixed", bottom: "20px" ,   width: "90%"}}
                fluid
              >
                SUBMIT
              </Button>
              <Button
                onClick={onClickHandler}
                negative
                type="submit"
                style={{  position: "fixed", bottom: "80px", width: "90%" }}
                fluid
              >
                CANCEL
              </Button>
            </Grid>
          </Form>
        </Container>
      )}
      {state.showSubmissionView && <Submit email={email} />}
    </>
  );
};

export default SignUp;
