import React from "react";
import { navigate } from "@reach/router";
import {
  Card,
  Grid,
  GridRow,
  Label,
  GridColumn,
  Icon,
  Button,
} from "semantic-ui-react";
import BatteryImage from "./BatteryImage";

export default ({ swap }) => {
  return (
    <Card style={{ width: "100%", marginTop: "10px", margin:"auto" }}>
      <Card.Header style={{ padding: "10px" }}>
        <strong style={{ color: "black", float: "left" }}>
          {" "}
          {swap.sharing_point_name}{" "}
        </strong>
        <Label
          color={swap.swap_state === "COMPLETED" ? "green" : "red"}
          style={{ float: "right" }}
          circular
        >
          <span>{swap.swap_state}</span>
        </Label>
      </Card.Header>
      <Card.Content>
        <Grid>
          <GridRow style={{ fontSize: "20px", color: "black" }}>
            <GridColumn verticalAlign="middle" textAlign="left" mobile={2}>
              <GridRow>
                {" "}
                <Icon name="clock outline" />
              </GridRow>
              <GridRow style={{ marginTop: "10px" }}>
                <Icon name="calendar alternate outline" />
              </GridRow>
            </GridColumn>
            <GridColumn verticalAlign="middle" mobile={6}>
              <GridRow textAlign="left">
                {new Date(swap.timestamp).getHours() +
                  ":" +
                  new Date(swap.timestamp).getMinutes()}
              </GridRow>
              <GridRow textAlign="left" style={{ marginTop: "10px" }}>
                {new Date(swap.timestamp).getFullYear() +
                  "-" +
                  (new Date(swap.timestamp).getMonth() + 1) +
                  "-" + 
                  new Date(swap.timestamp).getDate()}
              </GridRow>
            </GridColumn>
            <GridColumn style={{ padding: 0 }} textAlign="right" mobile={5}>
              <BatteryImage
                type={swap.battery_type}
                height="80px"
                width="auto"
                float={"right"}
              />
            </GridColumn>
            <GridColumn
              style={{ padding: 0 }}
              verticalAlign="middle"
              mobile={3}
            >
              <Label size="large" circular color="orange" image>
                x{swap.battery_count}
              </Label>
            </GridColumn>
          </GridRow>
          <GridRow>
            <Button
              style={{ width: "90%", margin: "auto" }}
              onClick={() =>
                navigate(`/order?token=${swap.validation_hash_key}`)
              }
              color="orange"
              circular
            >
              {" "}
              SHOW MORE DETAILS <Icon name="angle right" />
            </Button>
          </GridRow>
        </Grid>
      </Card.Content>
    </Card>
  );
};
