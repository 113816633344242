import React, { useEffect, useState } from "react";
import {
  Grid,
  GridRow,
  Icon,
  Modal,
  ModalContent,
  Button,
  ModalHeader,
  Message,
  GridColumn
} from "semantic-ui-react";

export default ({ deferredPrompt, dismiss, install }) => {
  const [isOpenIntallModal, setIsOpenIntallModal] = useState(true);

  const handleInstall = () => {
    install();
    setIsOpenIntallModal(false);
  };

  const handleDismiss = () => {
    dismiss();
    setIsOpenIntallModal(false);
  };

  return (
    <Modal
      centered={false}
      open={deferredPrompt && isOpenIntallModal}
      onClose={handleDismiss}
      size="mini"
    >
      <ModalHeader>Message</ModalHeader>
      <ModalContent>
        <Grid columns="1">
          <GridColumn>
            <Message
              info
              content="Install app for a better performance!"
              size="large"
              icon="info"
            />
          </GridColumn>

          <GridColumn>
            <Button
              size="massive"
              style={{ width: "100%" }}
              onClick={handleInstall}
              positive
              circular
              content="Install"
              icon="plus square outline"
            />
          </GridColumn>

          <GridColumn>
            <Button
              size="massive"
              style={{ width: "100%" }}
              onClick={handleDismiss}
              basic
              negative
              circular
              content="Dismiss"
              icon="cancel"
            />
          </GridColumn>
        </Grid>
      </ModalContent>
    </Modal>
  );
};
