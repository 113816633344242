import {
  Form,
  Button,
  Icon,
  Modal,
  Message,
  Input,
  FormField,
  Divider,
} from "semantic-ui-react";
import { useSelector, connect, useDispatch } from "react-redux";
import { login } from "../store/loginSlice";
import React, { useState } from "react";
import "./../App.scss";
import { navigate, Link } from "@reach/router";
import credentials from "../aws-exports/aws-exports-rel";
import { Auth } from "aws-amplify";

const LoginScreen = () => {
  const { isLoading, error, isLoggedIn } = useSelector((state) => state.login);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const loginHandler = (e) => {
    e.preventDefault();
    dispatch(login({ username, password }));
  };

  return (
    <Modal size="tiny" open={true}>
      <Modal.Header as="h3">Login</Modal.Header>
      <Modal.Content>
        <Form className={error ? "error" : ""}>
          <Form.Field>
            <label>username</label>
            <Input
              type="email"
              placeholder="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              size="big"
            />
          </Form.Field>

          <Form.Field>
            <label>password</label>
            <Input
              size="big"
              icon={
                <Icon
                  name={showPassword ? "eye" : "eye slash"}
                  onClick={() => setShowPassword(!showPassword)}
                  circular
                  link
                />
              }
              placeholder="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Link to="/forgot_password">Forgot password?</Link>
          </Form.Field>
          <FormField>
            <Button
              circular
              loading={isLoading}
              type="submit"
              onClick={loginHandler}
              positive
              style={{ color: "white", width: "100%" }}
              size="big"
              labelPosition="left"
              content="Login"
              icon="sign-in"
            />
          </FormField>
          <FormField>
            <Button
              circular
              type="submit"
              onClick={() => navigate("/")}
              negative
              style={{ width: "100%" }}
              size="big"
              labelPosition="left"
              content="Cancel"
              icon="close"
            />

            <FormField>
              <Divider horizontal>Or</Divider>
            </FormField>
            <FormField>
              <Button
                style={{ color: "#fff" }}
                circular
                color="google plus"
                icon="google"
                fluid
                size="big"
                content="Continue with Google"
                labelPosition="left"
                type="button"
                onClick={() => {window.location="https://swobbee-production.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://webapp-beta.swobbee.io/oauth2-callback/&response_type=TOKEN&client_id=71bgi3mdg791mn1okb1g5cg5e2&scope=email openid profile";}}
              />
            </FormField>
          </FormField>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
const mapDistpatch = { login };
export default connect(null, mapDistpatch)(LoginScreen);
