import * as L from "leaflet"

L.CenterControl = L.Control.extend({
  options: {
    position: "topleft"
  },
  initialize: function(options) {
    L.setOptions(this, options)
  },
  onAdd: function(map) {
    var container = L.DomUtil.create(
      "div",
      "leaflet-bar leaflet-control leaflet-control-custom"
    )
    let icon = L.DomUtil.create("i", "icon expand")
    container.style.backgroundColor = "white"
    container.title = 'center map'
    icon.style.fontSize = "25px"
    icon.style.display = "inline-block"
    icon.style.margin = 0
    container.appendChild(icon)
    container.append()
    container.onclick = () => {
      this.options.onClick()
    }
    return container
  }
})
L.centerControl = options => new L.CenterControl(options)
