import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { navigate } from "@reach/router";

const LoginButton = () => {
    return (
        <Button
            className="button-over-map"
            size="massive"
            style={{marginLeft: "30%"}}
            onClick={() => {
                navigate("/login")
            }}
            circular
            color="black"
        >
            <Icon name="sign in"></Icon>Login
        </Button>
    );
};

export default LoginButton;
