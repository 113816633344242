import React, { useEffect, useState } from "react";
import QrReader from "react-qr-reader";
import { navigate } from "@reach/router";
import { useSelector, connect, useDispatch } from "react-redux";
import { GridRow, Button, Grid, Icon, GridColumn } from "semantic-ui-react";
import { validateByQrCode, resetValidation } from "../store/validationSlice";
import { setError } from "../store/errorSlice";
import "../qrscanner.scss";
import AppLoader from "../components/AppLoader";
import uuid_validate from "uuid-validate";
import "./style.scss";

const QrScanner = () => {
  const [code, setCode] = useState();
  const {
    isLoading,
    unique_validation_hash_key,
    sharing_point_name
  } = useSelector(state => state.validation);
  const dispatch = useDispatch();

  const handleScan = scan => {
    if (scan) {
      try {
        const [url, search] = scan.split("?");
        if (search) {
          const code = new URLSearchParams(`?${search}`).get("sp");

          if (uuid_validate(code)) {
            setCode(code);
          } else {
            dispatch(setError({ message: "Invalid QR code", statusCode: 400 }));
            setCode(undefined);
          }
        } else {
          dispatch(setError({ message: "Invalid QR code", statusCode: 400 }));
        }
        setCode(undefined);
      } catch (error) {
        dispatch(setError({ message: "Invalid QR code", statusCode: 400 }));
      }
      setCode(undefined);
    }
  };

  useEffect(() => {
    if (code) {
      (async function startValidation() {
        await dispatch(validateByQrCode({ qrCode: code }));
      })();
    }
  }, [code]);

  useEffect(() => {
    if (unique_validation_hash_key) {
      setCode(false);
      localStorage.setItem(
        "validation",
        JSON.stringify({ unique_validation_hash_key, sharing_point_name })
      );
      dispatch(resetValidation());
    }
  }, [unique_validation_hash_key]);

  const handleError = error => {
    setError(error);
  };

  return (
    <Grid style={{ margin: "auto", height: "100vh" }}>
      <div className="base">
        <div className="hole"></div>
      </div>
      <div
        style={{
          width: "100%",
          position: "fixed",
          top: "50%",
          zIndex: 6,
          textAlign: "center"
        }}
      >
        <hr
          style={{
            width: "10%",
            borderColor: "green"
          }}
        ></hr>
      </div>

      <div
        style={{
          position: "fixed",
          top: "25%",
          zIndex: 1000,
          color: "white",
          textAlign: "center",
          fontSize: "18px",
          width: "100%"
        }}
      >
        <p
          style={{
            color: "white",

            fontSize: "18px"
          }}
        >
          Scan <Icon name="qrcode" />
        </p>
      </div>

      <GridRow>
        <GridColumn verticalAlign="middle" style={{ padding: "0px" }}>
          {isLoading && <AppLoader />}
          {!isLoading && (
            <QrReader
              style={{
                width: "100%",
                borderRadius: "50px",
                position: "relative"
              }}
              delay={100}
              onScan={handleScan}
              onError={handleError}
            />
          )}
        </GridColumn>
      </GridRow>
      <GridRow style={{ position: "fixed", bottom: "60px", zIndex: 1000 }}>
        <GridColumn textAlign="center" width={16}>
          <Button
            onClick={() => navigate("/")}
            negative
            circular
            icon="cancel"
            size="massive"
          ></Button>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

const mapDispatch = {
  validateByQrCode,
  resetValidation,
  setError
};
export default connect(null, mapDispatch)(QrScanner);
