import React, { useState } from "react";
import {
  Button,
  Form,
  Header,
  Grid,
  Container,
  Label,
  Message,
  Icon,
} from "semantic-ui-react";
import "./style.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Auth } from "aws-amplify";
import { navigate } from "@reach/router";

const defaultValues = {
  code: "",
};
const validationSchema = yup.object().shape({
  code: yup.string().required(),
});

const Verification = ({ email }) => {
  const [state, setstate] = useState({});
  const { handleSubmit, errors, register } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
  const submit = data => {
    Auth.confirmSignUp(email, data.code)
      .then(data => {
        setstate({ ...state, success: true, isLoading: false });
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      })
      .catch(err => {
        setstate({ ...state, success: false, isLoading: true });
      });
  };

  const onClickHandler = event => {
    event.preventDefault();
    navigate("/");
  };

  return (
    <Container style={{ marginTop: "100px", padding: "35px" }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Grid>
          <Grid.Row>
            <Header style={{ color: "white" }}>Account Verification</Header>
          </Grid.Row>
          {!state.isLoading && state.success && (
            <Grid.Row>
              <Message positive>
                <Icon name="check circle" /> Account Successfully Verified
              </Message>
            </Grid.Row>
          )}
          {state.isLoading && !state.success && (
            <Grid.Row>
              <Message negative>The code is not correct</Message>
            </Grid.Row>
          )}
          <Grid.Row>
            <label id="signup-label">Email</label>
          </Grid.Row>
          <Grid.Row>
            <span>{email}</span>
            <input type="email" disabled value={email} />
          </Grid.Row>
          <Grid.Row>
            <label id="signup-label">Code</label>
          </Grid.Row>
          <Grid.Row>
            <input type="text" name="code" placeholder="Code" ref={register} />
            {errors.code && (
              <Label pointing basic color="red">
                This field is required
              </Label>
            )}
          </Grid.Row>
          <Button
            primary
            type="submit"
            style={{ color: "white", marginTop: "60px" }}
            fluid
          >
            SUBMIT
          </Button>
          <Button
            onClick={onClickHandler}
            negative
            type="submit"
            style={{ color: "white", marginTop: "20px" }}
            fluid
          >
            CANCEL
          </Button>
        </Grid>
      </Form>
    </Container>
  );
};

export default Verification;
