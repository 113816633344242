import React from "react";
import { Loader } from "semantic-ui-react";

export default ({ inverted, marginTop }) => {
  return (
    <Loader
      style={{ marginTop: marginTop ? marginTop : "0px" }}
      size="massive"
      invert={inverted || "true"}
      active
    ></Loader>
  );
};
