import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { useSelector, useDispatch, connect } from "react-redux";
import { createOrder } from "../store/swapOrderSlice";
import { validateBygeoLocation } from "../store/validationSlice";
import { navigate } from "@reach/router";
import { closeSwapModal } from "../store/appState";

const CreateSwapOrderButton = ({ selectedSharingpoint, setOpen }) => {
  const swapOrder = useSelector(state => state.swapOrder.swapOrder);
  const { isLoading: orderLoading } = useSelector(state => state.swapOrder);
  const { isLoading } = useSelector(state => state.validation);

  const dispatch = useDispatch();

  const checkoutSharingpoint = async () => {
    if (swapOrder.unique_validation_hash_key) {
      dispatch(createOrder({ data: swapOrder }));
    }
  };

  const handleNavigateToscanner = () => {
    dispatch(closeSwapModal());
    navigate("/scan");
  };

  return (
    <>
      <Button
        onClick={() => {
          swapOrder.unique_validation_hash_key
            ? checkoutSharingpoint()
            : handleNavigateToscanner();
        }}
        loading={orderLoading || isLoading}
        size="huge"
        color="orange"
        style={{ borderRadius: "50px" }}
        fluid
      >
        {swapOrder.unique_validation_hash_key ? "SWAP" : "SCAN"}
      </Button>
    </>
  );
};
const mapDispatch = { createOrder, validateBygeoLocation };
export default connect(null, mapDispatch)(CreateSwapOrderButton);
