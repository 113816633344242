import React, { useState, useEffect } from "react";
import {
  Grid,
  GridRow,
  GridColumn,
  Container,
  Header,
  Button,
  Label,
  Divider,
  List,
  Image
} from "semantic-ui-react";
import { Auth } from "aws-amplify";

const SignUp = props => {
  const [state, setstate] = useState({});
  const fetchUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setstate({ ...state, user: user });
  };
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Container style={{
        width: "100%"
      }}>
      {state.user && (
        <Grid
          style={{
            width: "100%",
            color: "white"
          }}
        >
          <GridRow style={{width: "100%"}}>
            <GridColumn style={{ paddingTop: "80px" }}>
              <Header as="h2" style={{ color: "white" }}>
                Hi {state.user.attributes.name}!
              </Header>
            </GridColumn>
          </GridRow> 
          <Divider />
          <GridRow  style={{width: "100%"}}>
            <GridColumn width="16">
              <List
                divided
                relaxe
                style={{ color: "white", backgroundColor: "white" , width: "100%"}}
                selection
                verticalAlign="middle"
              >
                <List.Item>
                  <List.Content>
                    <List.Header style={{padding: "10px"}}>username: {state.user.username}</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header style={{padding: "10px"}}>
                      email: {state.user.attributes.email}
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header style={{padding: "10px"}}>
                      state:{" "}
                      {state.user.attributes.email_verified && (
                        <Label color="green">verified</Label>
                      )}
                      {!state.user.attributes.email_verified && (
                        <Label color="red">pending</Label>
                      )}
                    </List.Header>
                  </List.Content>
                </List.Item>
              </List>
            </GridColumn>
          </GridRow>

          {/* <GridRow>
            <GridColumn>username: {state.user.username}</GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn>
              email: {state.user.attributes.email}{" "}
              
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn>state: {state.user.attributes.email_verified && (
                <Label color="green">verified</Label>
              )}
              {!state.user.attributes.email_verified && (
                <Label color="red">pending</Label>
              )}
              </GridColumn>
          </GridRow> */}
        </Grid>
      )}
      <Button
        negative
        style={{ position: "fixed", bottom: 0 }}
        size="huge"
        fluid
      >
        {" "}
        SIGN OUT
      </Button>
    </Container>
  );
};

export default SignUp;
