import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "semantic-ui-react";
import BatterySelectionModal from "../components/BatterySelectionModal";
import SetBatteryNumberModal from "../components/SetBatteryNumberModal";
import { openSwapModal, showBatterySelection } from "../store/appState";

const ModalTransition = () => {
  const {
    isOpenSwapModal,
    isOpenBatterySelection,
    isOpenSetBatteryNumber
  } = useSelector(state => state.appState);

  const { selectedSharingpoint, success } = useSelector(
    state => state.sharingpoints
  );

  const dispatch = useDispatch();
  const [batteryOptions, setBatteryOptions] = useState();

  useEffect(() => {
    if (success) {
      dispatch(openSwapModal());
      dispatch(showBatterySelection());
    }
  }, [selectedSharingpoint]);

  return (
    <Container>
      {isOpenBatterySelection && isOpenSwapModal ? (
        <BatterySelectionModal
          batteryOptions={batteryOptions}
          setBatteryOptions={setBatteryOptions}
        />
      ) : (
        isOpenSwapModal &&
        isOpenSetBatteryNumber && (
          <SetBatteryNumberModal batteryOptions={batteryOptions} />
        )
      )}
    </Container>
  );
};

export default ModalTransition;
