import React, { useState } from "react";
import { Grid, GridColumn, GridRow, Image, Button, Divider, Message, Icon } from "semantic-ui-react";
import NumberInput from "semantic-ui-react-numberinput";
import CancelSwapButton from "../CancelSwapButton";

const SignUp = props => {
  const [state, setstate] = useState({});
  const [doorCount, setDoorCount] = useState(1);


  return (
    <Grid>
        <GridRow centered>
        <GridColumn textAlign="center">
          <Message info >
               <Icon size="big" name="info"/> How many Doors do you want to open?
          </Message>
        </GridColumn>
      </GridRow>
      <GridRow centered>
        <GridColumn textAlign="center">
          <img
            style={{ margin: "auto" }}
            alt=""
            src={require("../../assets/images/station.png")}
          />
        </GridColumn>
      </GridRow>
      <GridRow centered>
        <GridColumn textAlign="center"> 
          <NumberInput
            value={doorCount}
            maxValue={
              5
            }
            minValue={1}
            onChange={(value)=> setDoorCount(value)}
            className="numberInput"

          />
        </GridColumn>
      </GridRow>
      <Divider/>
      <Grid.Row verticalAlign="bottom" centered>
        <GridColumn textAlign="center" >
          <Button
            
            size="huge"
            color="orange"
            style={{ width: "90%", borderRadius: "50px", margin: "auto" }}
          >
            OPEN DOOR
          </Button>
        </GridColumn>
      </Grid.Row>
      <Grid.Row verticalAlign="bottom">
        <GridColumn textAlign="center">
          <CancelSwapButton />
        </GridColumn>
      </Grid.Row>
    </Grid>
  );
};

export default SignUp;
