import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { checkUserPosition, validateQrCode } from "../graphql/mutations";
import { setError } from "./errorSlice";
import { navigate } from "@reach/router";

export const validateBygeoLocation = createAsyncThunk(
  "validateBygeoLocation",
  async ({ longitude, latitude }) => {
    let { data } = await API.graphql(
      graphqlOperation(checkUserPosition, { longitude, latitude })
    );
    return data;
  }
);

export const validateByQrCode = createAsyncThunk(
  "validateByQrCode",
  async ({ qrCode }, { dispatch }) => {
    //window.navigator.vibrate(200);
    let { data } = await API.graphql(
      graphqlOperation(validateQrCode, { code: qrCode })
    );
      if (+data.validate_qr_code.code === 200) {
      return data;
    } else {
      dispatch(
        setError({
          message: data.validate_qr_code.message,
          statusCode: data.validate_qr_code.code
        })
      );
    }
  }
);

const validationSlice = createSlice({
  name: "validation",
  initialState: {
    sharing_point_name: "",
    unique_validation_hash_key: "",
    error: "",
    isLoading: false
  },
  reducers: {
    deleteError: () => {},
    resetValidation: (state, actions) => {
      state.sharing_point_name = "";
      state.unique_validation_hash_key = "";
    }
  },
  extraReducers: {
    [validateBygeoLocation.pending]: (state, actions) => {
      state.isLoading = true;
    },
    [validateBygeoLocation.fulfilled]: (state, actions) => {
      if (actions.payload.validate_position) {
        const {
          unique_validation_hash_key,
          sharing_point_name
        } = actions.payload.validate_position.data;
        state.unique_validation_hash_key = unique_validation_hash_key;
        state.sharing_point_name = sharing_point_name;
        state.isLoading = false;
        if (actions.payload.validate_position.code === "404") {
          state.error = "you have to be in 5m range";
        }
      }
      navigate("/");
    },
    [validateBygeoLocation.rejected]: (state, actions) => {
      state.error = actions;
      state.isLoading = false;
    },

    [validateByQrCode.pending]: (state, actions) => {
      state.isLoading = true;
    },
    [validateByQrCode.fulfilled]: (state, actions) => {
      state.isLoading = false;

      if (actions.payload) {
        const {
          unique_validation_hash_key,
          sharing_point_name
        } = actions.payload.validate_qr_code.data;

        state.unique_validation_hash_key = unique_validation_hash_key;
        state.sharing_point_name = sharing_point_name;
        if (actions.payload.validate_qr_code.code === "404") {
          state.error = "invalide qr code";
        }
        navigate("/");
      }
    },
    [validateByQrCode.rejected]: (state, actions) => {
      state.error = actions;
      state.isLoading = false;
    }
  }
});

export const { resetValidation } = validationSlice.actions;

export default validationSlice.reducer;
