import React, { useEffect, useState } from "react";
import SharingPointCard from "../components/SharingPointCard";
import AppLoader from "../components/AppLoader";
import {
  GridRow,
  Container,
  Grid,
  Dropdown,
  Button,
  GridColumn,
  Icon,
  Transition,
  Loader
} from "semantic-ui-react";
import { fetchSharingpoints } from "../store/sharingpointSlice";
import { hideShowFilterModal, showFilterModal } from "../store/appState";
import { connect, useDispatch, useSelector } from "react-redux";
import "./../assets/styles/sharingpoints.scss";
import { radiusOptions } from "./../assets/json";
import "./style.scss";
import ScreenHeader from "../components/ScreenHeader";

const Sharingpoints = ({ location }) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(state => state.sharingpoints);
  const { isLoggedIn, username } = useSelector(state => state.login);

  const { latitude, longitude } = useSelector(
    state => state.user.currentGeocode
  );
  const { isOpenFilterModal } = useSelector(state => state.appState);

  const [radius, setRadius] = useState(1);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(
        fetchSharingpoints({
          latitude: latitude || 52.523882541879,
          longitude: longitude || 13.382704755785,
          radius
        })
      );
      return;
    }
  }, [username]);

  const handleDropdownRadius = value => {
    setRadius(value);
    dispatch(
      fetchSharingpoints({
        latitude: latitude || 52.523882541879,
        longitude: longitude || 13.382704755785,
        radius: value
      })
    );
  };

  return (
    <Container
      className="container-sps"
      style={{
        padding: "0px"
        //width: "50%"
      }}
    >
      <ScreenHeader text={"Sharingpoints"} />
      <Grid
        textAlign="left"
        style={{
          padding: "0px",
          width: "90%",
          marginLeft: "5%",
          marginTop: "60px"
        }}
      ></Grid>
      {isLoading && data.length === 0 && (
        <Loader
          size="huge"
          inline
          active
          inverted
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            marginTop: "-50px",
            marginLeft: "-30px"
          }}
        />
      )}
      <>
        {((data ? data : []).length || [] > 0) &&
          data.map((sp, i) => (
            <GridRow key={i} style={{ padding: "0px", marginTop: "20px" }}>
              <SharingPointCard sharingpoint={sp} />
            </GridRow>
          ))}
      </>
    </Container>
  );
};

const mapDispatch = {
  fetchSharingpoints,
  hideShowFilterModal,
  showFilterModal
};
export default connect(null, mapDispatch)(Sharingpoints);
