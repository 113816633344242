import React, { useState } from "react";
import { Image, Loader } from "semantic-ui-react";

const imageUrls = {
  greenpack: require("../assets/images/batteries/greenpack.png"),
  kumpan: require("../assets/images/batteries/kumpan.png"),
  torrot: require("../assets/images/batteries/torrot.png"),
  "Okai A": require("../assets/images/batteries/okai.png"),
  Tier_dev: require("../assets/images/batteries/okai.png"),
  "Tier dev": require("../assets/images/batteries/okai.png"),
  "Okai B": require("../assets/images/batteries/okaib.png"),
  Okai_B: require("../assets/images/batteries/okaib.png"),
  Okai_A: require("../assets/images/batteries/okai.png"),
  Segway: require("../assets/images/batteries/segway.png"),
  "AES_eBike_Akku_2.0_48V": require("../assets/images/batteries/AES_eBike_Akku_04.png"),
  "AES eBike_Akku_2.0_48V": require("../assets/images/batteries/AES_eBike_Akku_04.png"),
  "AES eBike Akku 2.0 48V": require("../assets/images/batteries/AES_eBike_Akku_04.png")
};

export default ({ type, height, width, float }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      {type && (
        <img
          alt=""
          style={{
            float: float,
            margin: "auto",
            height,
            width,
            display: loaded ? "block" : "none",
            padding: 0
          }}
          onLoad={() => {
            setLoaded(true);
          }}
          src={imageUrls[type]}
        />
      )}

      {type && !imageUrls[type] && <span>{ type }</span>}
      {!loaded && imageUrls[type]&&(
        <div style={{ margin: "auto", height, width }}>
          <Loader active inline />
        </div>
      )}
    </>
  );
};
