import React from "react";

export default ({ text }) => {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        marginTop: "20px",
        color: "white"
      }}
    >
      <h3 >{text}</h3>
    </div>
  );
};
