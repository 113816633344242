import React, { useEffect, useState, useMemo } from "react";
import {
  Grid,
  Modal,
  GridRow,
  GridColumn,
  Button,
  TransitionablePortal,
  Divider,
  Container,
  Message,
  Icon,
  Header
} from "semantic-ui-react";
import { useSelector, connect, useDispatch } from "react-redux";
import {
  closeSwapModal,
  openSwapModal,
  setLoading,
  hideSetBatteryNumber
} from "../store/appState";
import BatteryImage from "./BatteryImage";
import NumberInput from "semantic-ui-react-numberinput";
import CreateSwapOrderButton from "../components/CreateSwapOrderButton";
import CancelSwapButton from "../components/CancelSwapButton";
import { setBatteryCount } from "../store/swapOrderSlice";
import ForceOpenDoor from "./ForceOpenDoor";

import "../App.scss";

const BatterySelectionModal = () => {
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const { isOpenSwapModal, isOpenSetBatteryNumber } = useSelector(
    state => state.appState
  );
  const { selectedSharingpoint } = useSelector(state => state.sharingpoints);
  const { battery_type, battery_count } = useSelector(
    state => state.swapOrder.swapOrder
  );
  const validation_error = useSelector(state => state.validation.error);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(openSwapModal());
  }, [selectedSharingpoint]);

  const handleBatteryCountInput = value => {
    dispatch(setBatteryCount(value));
  };
  const maxValue = useMemo(() => {
    if (selectedSharingpoint.swap_limit) {
      return selectedSharingpoint.swap_limit;
    } else {
      return selectedSharingpoint.sp_type === "ipc" &&
        selectedSharingpoint.force_to_swap_allowed
        ? JSON.parse(selectedSharingpoint.sp_bat_inside_info)[
            battery_type.replace(" ", "_")
          ]
        : JSON.parse(selectedSharingpoint.sp_bat_ready_info)[
            battery_type.replace(" ", "_")
          ];
    }
  }, [selectedSharingpoint]);

  return (
    <Container>
      <TransitionablePortal
        open={isOpenSwapModal}
        transition={{ animation: "scale", duration: 500 }}
      >
        <Modal
          size="mini"
          centered={false}
          open={isOpenSetBatteryNumber && isOpenSwapModal}
          onClose={() => {
            dispatch(closeSwapModal());
            dispatch(hideSetBatteryNumber());
          }}
          closeIcon
          className="bt-sl-mdl"
        >
          <Modal.Header>
            <span>
              {selectedSharingpoint && selectedSharingpoint.sharing_point_name}
            </span>
          </Modal.Header>
          <Modal.Content>
            {!showMoreOptions && (
              <Grid centered>
                <GridColumn width="16">
                  <Message
                    info
                    content="Set battery amount and press swap"
                    icon="info"
                    header="Step 2"
                  />
                </GridColumn>
                {validation_error && (
                  <GridRow>
                    <Message
                      style={{ width: "90%" }}
                      floating
                      error
                      content={validation_error}
                    />
                  </GridRow>
                )}

                {+JSON.parse(selectedSharingpoint.sp_bat_ready_info)[
                  battery_type.replace(" ", "_")
                ] < battery_count && (
                  <GridRow>
                    <GridColumn>
                      <Message
                        warning
                        floating
                        icon="warning"
                        header="Warning"
                        content={`By requesting more than
                        ${+JSON.parse(selectedSharingpoint.sp_bat_ready_info)[
                          battery_type.replace(" ", "_")
                        ]} Batteries you ll get batteries with state of charge bellow 85%.`}
                      />
                    </GridColumn>
                  </GridRow>
                )}
                <Grid.Row>
                  <BatteryImage
                    width={"auto"}
                    height={"150px"}
                    type={battery_type}
                  />
                </Grid.Row>
                <GridRow>
                  <NumberInput
                    value={battery_count.toString()}
                    maxValue={maxValue}
                    minValue={1}
                    onChange={handleBatteryCountInput}
                    size="big"
                    className={
                      +JSON.parse(selectedSharingpoint.sp_bat_ready_info)[
                        battery_type.replace(" ", "_")
                      ] < battery_count
                        ? "numberInputRed"
                        : "numberInput"
                    }
                    color="red"
                  />
                </GridRow>

                {JSON.parse(selectedSharingpoint.sp_bat_ready_info)[
                  battery_type.replace(" ", "_")
                ] > 5 &&
                  maxValue > 5 && (
                    <GridRow>
                      <Button.Group
                        size="huge"
                        style={{ width: "85%", color: "white" }}
                      >
                        <Button
                          onClick={() => dispatch(setBatteryCount(1))}
                          disabled={
                            JSON.parse(selectedSharingpoint.sp_bat_ready_info)[
                              battery_type.replace(" ", "_")
                            ] !== battery_count
                          }
                          negative
                          circular
                        >
                          -
                          {
                            JSON.parse(selectedSharingpoint.sp_bat_ready_info)[
                              battery_type.replace(" ", "_")
                            ]
                          }
                        </Button>
                        <Button.Or text="or" />
                        <Button
                          onClick={() => {
                            dispatch(
                              setBatteryCount(
                                JSON.parse(
                                  selectedSharingpoint.sp_bat_ready_info
                                )[battery_type.replace(" ", "_")]
                              )
                            );
                          }}
                          disabled={
                            JSON.parse(selectedSharingpoint.sp_bat_ready_info)[
                              battery_type.replace(" ", "_")
                            ] === battery_count
                          }
                          positive
                          circular
                        >
                          +
                          {
                            JSON.parse(selectedSharingpoint.sp_bat_ready_info)[
                              battery_type.replace(" ", "_")
                            ]
                          }
                        </Button>
                      </Button.Group>
                    </GridRow>
                  )}
                <Divider />
                {window.innerWidth <= 415 && (
                  <GridColumn width="16">
                    <CreateSwapOrderButton
                      selectedSharingpoint={selectedSharingpoint}
                      setOpen={setOpen}
                    />
                  </GridColumn>
                )}

                <GridColumn width="16">
                  <CancelSwapButton closeModal={closeSwapModal} />
                </GridColumn>
              </Grid>
            )}

            {showMoreOptions && <ForceOpenDoor />}
          </Modal.Content>
        </Modal>
      </TransitionablePortal>
      <Modal
        style={{ zInex: "999999" }}
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="small"
      >
        <Header icon>
          <Icon name="warning" />
          Warning
        </Header>
        <Modal.Content>
          <p>Are you sure?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            inverted
            onClick={() => {
              setOpen(false);
              dispatch(closeSwapModal());
            }}
          >
            <Icon name="remove" /> No
          </Button>
          <Button
            color="green"
            inverted
            onClick={() => {
              setOpen(false);
              dispatch(closeSwapModal());
            }}
          >
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

const mapDispatch = { closeSwapModal, setBatteryCount, setLoading };
export default connect(null, mapDispatch)(BatterySelectionModal);
