/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSharingpointMapdataQuery = `query GetSharingpointMapdataQuery($latitude: Float! $longitude: Float! $radius: Int!) {
  sharingpoints (latitude:$latitude longitude:$longitude radius:$radius ){
    code
    success
    message
    data {
      w3w
      sharing_point_name
      zipcode
      latitude
      longitude
      city
      distance
      sp_type
      sp_bat_ready_info
      street_address
      street_number
      sharing_point_status
      force_to_swap_allowed 
      sp_bat_inside_info
      nick_name
    }
  }
}
`;
// ($latitude: Float! $longitude: Float! $radius: Int!)
// (latitude:$latitude longitude:$longitude radius:$radius )

export const getMySwapOrderBySpName = `query getMySwapOrderBySpName($token: String!) {
  order(token: $token ){
    code
    success
    message
    data{
      sharing_point_name
      user_name
      swap_state
      battery_count
      swap_status_map
      battery_type
      confirmation_token
      validation_hash_key
      box_status{
        boxes{
          box_out
          box_in
          box_id
          slots {
            slot_id
            is_in
            is_out
            state
          }
        }
        global_box_in
        global_box_out
      }
    }
    errors{
      err_message
      data
    }
  }
}
`;

// export const getSharingpointDetaildata = `
//   query sharingpoint($sharing_point_name: String!) {
//     sharingpoint(sharing_point_name:$sharing_point_name){
//       code
//       success
//       message
//       data{
//         sharing_point_name
//         ongoing_swap
//         sp_bat_inside_info
//         sp_bat_ready_info
//         timestamp
//         swap_limit
//         supported_batteries
//         box_info {
//           reserved
//           reserved_until
//           bat_ready_count
//           battery_type
//           empty
//         }
//       }
//       errors{
//         err_message
//         data
//       }
//     }
//   }

// `;

export const getSharingpointDetaildata = `
  query sharingpoint($sharing_point_name: String!) {
    sharingpoint(sharing_point_name:$sharing_point_name){
      code
      success
      message
      data{
        sharing_point_name
        ongoing_swap
        sp_bat_inside_info
        sp_bat_ready_info
        timestamp
        swap_limit
        supported_batteries
        box_info {
          reserved
          reserved_until
          bat_ready_count
          battery_type
          empty
        }
      }
      errors{
        err_message
        data
      }
    }
  }

`;


export const getMySwapHistory = `query getMySwapHistory($sharing_point_name: String!) {
  order(sharing_point_name:$sharing_point_name){
    data{
      sharing_point_name
      validation_hash_key
      user_name
      swap_state
      battery_count
      timestamp
      swap_status_map
    }
  }
  }

`;

export const getCitiesWithSharingPoints = `query getCitiesWithSharingPoints {
  cities{
    code
    success
    message
    data
  }
}`;



export const queryOrderHistory = `query history{
    history{
      data{
        battery_type
        battery_count
        sharing_point_name
        user_name
        swap_state
        timestamp
        validation_hash_key 
      }
    }
  }
  `
