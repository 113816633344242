import React from "react";
import Slot from "./Slot";
import { Grid, GridColumn } from "semantic-ui-react";

export default ({ slots, battery_type }) => {
  console.log(battery_type);
  if (battery_type !== "AES eBike Akku 2.0 48V") {
    return (
      <div
        className={
          battery_type === "Okai B" ? "wrapper-2-boxes" : "wrapper-3-boxes"
        }
      >
        {slots &&
          slots.map((slot, i) => (
            <div className={`box${i}`}>
              <Slot key={i} index={i} slot={slot} />
            </div>
          ))}
      </div>
    );
  } else {
    return (
      <Grid columns="4">
        {slots && slots.map((slot, index) => {
         return (
          <GridColumn> 
             <Slot key={index} index={index} slot={slot} size="big" />
          </GridColumn>
         ) 
        })}
      </Grid>
    );
  }
};
