import axios from "axios";
import React, { useEffect } from "react";
import awsmobile from "../aws-exports/aws-exports-rel";
import decodeJwt from "jwt-decode";
import { Auth } from "aws-amplify";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import {Container} from "semantic-ui-react"


export default function Oauth2Callback({ location }) {
  const injectTokensIntoAmplify = (accessToken, idToken, refreshToken) => {
    try {
      console.log(accessToken, idToken, refreshToken);
      const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({
          IdToken: idToken,
        }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: refreshToken,
        }),
        AccessToken: new CognitoAccessToken({
          AccessToken: accessToken,
        }),
      });
      Auth.configure(session, "session");
      const currentUser = Auth.createCognitoUser(
        session.getIdToken().decodePayload()["cognito:username"]
      );
      currentUser.setSignInUserSession(session);
      window.location= "/"
    } catch (error) {
      console.log(error);
    }
  };

  const oauth2Signup = async (access_token, id_token) => {
    try {
      if (access_token && id_token) {
        await axios.post(awsmobile.FLASK_REST_API + "/oauth2-signup", {
          access_token: decodeJwt(access_token),
          id_token: decodeJwt(id_token),
        });
        injectTokensIntoAmplify(access_token, id_token, "");
      }
    } catch (error) {
      window.location = `${awsmobile["oauth2-signup"]}&access_token=${access_token}&id_token=${id_token}`;
    }
  };

  useEffect(() => {
    oauth2Signup(
      new URLSearchParams(location.hash).get("#access_token"),
      new URLSearchParams(location.hash).get("id_token")
    );
    return () => {};
  }, []);

  return (
    <Container
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span style={{ color: "white", margin: 20 }}>Redirecting...</span>
    </Container>
  );
}
