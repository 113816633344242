import React, { useEffect, useState } from "react";
import Sharingpoints from "./screens/SharingPoints";
import { Location, Router, Redirect } from "@reach/router";
import BurgerMenu from "./components/BurgerMenu";
import MainMap from "./components/MainMap";
import Login from "./screens/Login";


import {
  Container,
  Message,
  Loader,
  Grid,
  GridColumn,
} from "semantic-ui-react";


import {
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from "amazon-cognito-identity-js";
import { useDispatch, connect } from "react-redux";
import { checkSession } from "./store/loginSlice";
import { selectSharingpoint } from "./store/sharingpointSlice";
import { setCurrentGeocode } from "./store/userSlice";
import { useSelector } from "react-redux";
import ModalTransition from "./components/ModalTransition";
import History from "./screens/History";

import QrScanner from "./screens/QrScanner";
import SwapOrder from "./screens/SwapOrder";
import { Offline } from "react-detect-offline";
import { onEntryChange } from "./graphql/subscriptions";
import { fetchSharingpoints } from "./store/sharingpointSlice";

import {
  setUniqueValidationHashKey,
  setSharingpointName,
} from "./store/swapOrderSlice";
import "./assets/styles/qrscanner.scss";
import InstallModal from "./components/InstallModal";
import ErrorHandler from "./components/ErrorHandler";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import Profile from "./components/Profile";
import NavigationBar from "./components/NavigationBar";
import Settings from "./components/Settings";

import { API, graphqlOperation } from "aws-amplify";
import Stage from "./components/Stage";
import { ErrorBoundary } from "react-error-boundary";
import axios from "axios";
import awsmobile from "./aws-exports/aws-exports-rel";
import Oauth2Callback from "./components/Oauth2Callback";

const App = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.sharingpoints);
  const { isLoggedIn } = useSelector((state) => state.login);
  const [userInfo, setUserInfo] = useState();
  const [auth, setAuth] = useState();

  const { longitude, latitude } = useSelector(
    (state) => state.user.currentGeocode
  );

  const [deferredPrompt, setdeferredPrompt] = useState(null);

  useEffect(() => {
    dispatch(checkSession());
    if ((data ? data : []).length > 0 && localStorage.getItem("validation")) {
      const { sharing_point_name, unique_validation_hash_key } = JSON.parse(
        localStorage.getItem("validation")
      );

      const sharingpoint = data.filter(
        (sp) => sp.sharing_point_name === sharing_point_name
      );
      dispatch(selectSharingpoint(sharingpoint[0]));
      dispatch(setSharingpointName(sharing_point_name));
      dispatch(setUniqueValidationHashKey(unique_validation_hash_key));
      localStorage.removeItem("validation");
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setdeferredPrompt(e);
    });
    window.addEventListener("appinstalled", () => {
      setdeferredPrompt(null);
    });
  });

  const dismiss = async () => {
    setdeferredPrompt(null);
  };
  const install = async () => {
    deferredPrompt.prompt();
  };

  useEffect(() => {
    API.graphql(graphqlOperation(onEntryChange)).subscribe({
      next: ({ value }) => {
        if (value.data.entryChange.source !== "SHARINGPOINT_CHANGED")
          dispatch(
            fetchSharingpoints({
              latitude: latitude || 52.523882541879,
              longitude: longitude || 13.382704755785,
              radius: 1,
            })
          );
      },
    });
    fetchUserInfo(
      localStorage.getItem(
        `CognitoIdentityServiceProvider.6bf0rh9u3kjtil6gfcinfigrem.LastAuthUser`
      )
    );
  }, []);

  const injectTokensIntoAmplify = (accessToken, idToken, refreshToken) => {
    const session = new CognitoUserSession({
      IdToken: new CognitoIdToken({
        IdToken: idToken,
      }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: refreshToken,
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: accessToken,
      }),
    });
    // Auth.configure(session, "session");

    // const currentUser = Auth.createCognitoUser(
    //   session.getIdToken().decodePayload()["cognito:username"]
    // );

    // currentUser.setSignInUserSession(session);
  };

  const login = async () => {
    const { data } = await axios.post(awsmobile.FLASK_REST_API + "/login", {
      username: "b.bellafkir@swobbee.com",
      password: "Rmdwin11%",
      client_id: awsmobile.aws_user_pools_web_client_id,
    });
    setAuth(data.data);
  };

  useEffect(() => {
    login();
  }, []);

  useEffect(() => {
    if (!auth) return;
    const { access_token, id_token, refresh_token } = auth;
    console.log(access_token, id_token, refresh_token);
    // injectTokensIntoAmplify(access_token, id_token, refresh_token);
    // Auth.federatedSignIn("COGNITO",{
    //   provider: "COGNITO",
    //   token: access_token,
    //   expires_at: 36000 * 1000 + new Date().getTime(), // the expiration timestamp
    // })
    //   .then((cred) => {
    //     // If success, you will get the AWS credentials
    //     console.log(cred);
    //     return Auth.currentAuthenticatedUser();
    //   })
    //   .then((user) => {
    //     // If success, the user object you passed in Auth.federatedSignIn
    //     console.log(user);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }, [auth]);

  const fetchUserInfo = async (username) => {
    try {
      const { data } = await axios.get(
        awsmobile.FLASK_REST_API + `/profile/${username}`
      );
      setUserInfo(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!userInfo) return;
    console.log(userInfo.logout_user_after_min);
  }, [userInfo]);

  function ErrorFallback({ error }) {
    return (
      <Message
        style={{ margin: 40 }}
        error
        header="Something went wrong"
        list={[error.message]}
      />
    );
  }

  return (
    <div id="app" style={{ width: "100%" }}>
      <Container style={{ width: "100%", position: "relative" }}>
        <InstallModal
          dismiss={dismiss}
          install={install}
          deferredPrompt={deferredPrompt}
        />
        <Offline style={{ position: "absolute" }}>
          <Message
            color="yellow"
            style={{
              padding: "0 0 0 20px",
              position: "fixed",
              zIndex: 9999,
              width: "100%",
            }}
          >
            <Grid>
              <GridColumn mobile={2}>
                <Loader active />
              </GridColumn>
              <GridColumn mobile={14}>
                No Internet connection available
              </GridColumn>
            </Grid>
          </Message>
        </Offline>
        <ErrorHandler />
        {isLoggedIn && <NavigationBar />}
        {isLoggedIn && (
          <BurgerMenu pageWrapId={"page-wrap"} outerContainerId={"App"} />
        )}

        <Location>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Container style={{ width: "100%" }} id="page-wrap" as={Router}>
              <MainMap path="/" />
              <Sharingpoints path="/sharingpoints" />
              {window.innerWidth <= 415 && <QrScanner path="/scan" />}
              <Login path="login" />
              <SwapOrder path="/order" />
              <History path="/orders" />
              <SignUp path="/signup" />
              <Settings path="/settings" />
              <ForgotPassword path="/forgot_password" />
              <Profile path="/profile" />
              <Oauth2Callback path="/oauth2-callback" />


              <Redirect noThrow from="*" to="/" />
            </Container>
          </ErrorBoundary>
        </Location>

        <ModalTransition />
        {process.env.REACT_APP_MODE !== "production" && <Stage />}
      </Container>
    </div>
  );
};
const mapDispatch = {
  checkSession,
  selectSharingpoint,
  setCurrentGeocode,
  fetchSharingpoints,
};
export default connect(null, mapDispatch)(App);
