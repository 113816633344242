/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const notifyOnSwapState = `mutation NotifyOnSwapState(
  $sharing_point_name: String!
  $validation: String!
  $user_name: String!
  $code: String
  $success: Boolean!
  $message: String!
  $swap_state: [String]!
  $err_message: String
  $err_data: AWSJSON
) {
  notifyOnSwapState(
    sharing_point_name: $sharing_point_name
    validation: $validation
    user_name: $user_name
    code: $code
    success: $success
    message: $message
    swap_state: $swap_state
    err_message: $err_message
    err_data: $err_data 
  ) {
    code
    success
    message
    sharing_point_name
    validation
    user_name
    data {
      sharing_point_name
      swap_state
      user_name
      validation
    }
    errors {
      err_message
      data
    }
  }
}
`;
export const createSwapOrder = `mutation order(
  $sharing_point_name: String!
  $unique_validation_hash_key: String!
  $battery_type: String!
  $battery_count: Int!
) {
  order(
    sharing_point_name: $sharing_point_name
    unique_validation_hash_key: $unique_validation_hash_key
    battery_type: $battery_type
    battery_count: $battery_count
  ) {
    code
    success
    message
    errors {
      err_message
      data
    }
    data {
      sharing_point_name
      validation_hash_key
      user_name
    }
  }
}
`;

export const validateQrCode = `mutation validateQrCode($code: String!)  {
  validate_qr_code(code: $code){
    code
    success
    message
    data{
		  unique_validation_hash_key
      sharing_point_name
			}
  }
}
`;

export const checkUserPosition = `mutation checkUserPosition($longitude: Float!,$latitude:Float!) {
  validate_position(longitude:$longitude,latitude:$latitude){
    code
    success
    message
    data{
      sharing_point_name
      unique_validation_hash_key
    }
    errors{
      err_message
      data
    }
  }
}`;

export const confirmOpenDoor = `
mutation ConfirmOpenDoor($token: String!) {
  open(token: $token ){
  data
  code
  message
  success
  errors{
    err_message
    data
    }
  }
}
`;

export const cancelOrder = `
mutation cancellation($token: String!) {
  cancellation(token: $token ){
    data
    code
    message
    success
    errors{
      err_message
      data
    }
  }
}
`;

export const openDoors = `mutation openDoors($sharing_point_name: String!, $battery_type: String!, $token: String!) {
  openDoors(sharing_point_name: $sharing_point_name, battery_type: $battery_type, token: $token) {
    data{
      doors 
      swap_id
      token
    } 
    code
    message
    success
    errors {
      err_message
      data 
    }
  }
}`;
