import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentGeocode: {
      longitude: 13.523481, 
      latitude: 52.431877
    }
  },
  reducers: {
    setCurrentGeocode: (state, actions) => {
      state.currentGeocode.longitude = actions.payload.longitude;
      state.currentGeocode.latitude = actions.payload.latitude;
    }
  }
});
export const {setCurrentGeocode} = userSlice.actions;

export default userSlice.reducer;
