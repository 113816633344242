import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { createSwapOrder } from "../graphql/mutations";
import { getMySwapOrderBySpName, queryOrderHistory } from "../graphql/queries";
import { setError } from "./errorSlice";
import { closeSwapModal } from "./appState";

import { confirmOpenDoor, cancelOrder } from "../graphql/mutations";

import { sub2SwapStateNotification } from "../graphql/subscriptions";
import { navigate } from "@reach/router";

export const createOrder = createAsyncThunk(
  "createOrder",
  async (swapOrder, { dispatch }) => {
    let { data } = await API.graphql(
      graphqlOperation(createSwapOrder, swapOrder.data)
    );
    const {message, code, success}=data.order
    
    if (!success) {
      dispatch(setError({ message, statusCode: +code }));
      return data
    } else {
      dispatch(closeSwapModal());
      return data;
    }
  }
);

export const fetchSwapOrderBySpName = createAsyncThunk(
  "fetchSwapOrderBySpName",
  async (token) => {
    let { data } = await API.graphql(
      graphqlOperation(getMySwapOrderBySpName, { token })
    );
    return data;
  }
);

export const subscribeToOrder = createAsyncThunk(
  "subscribeToOrder",
  async ({ sharing_point_name, user_name, unique_validation_hash_key }) => {
    console.log(sharing_point_name, user_name, unique_validation_hash_key);

    if (sharing_point_name && user_name && unique_validation_hash_key) {
      let { data } = await API.graphql(
        graphqlOperation(sub2SwapStateNotification, {
          sharing_point_name,
          user_name,
          unique_validation_hash_key,
        })
      ).subscribe({
        next: (eventData) => {
          const s = eventData.value.data;
          console.log(s);
        },
      });
      return data;
    }
  }
);

export const confirm_open_door = createAsyncThunk(
  "confirmOpenDoor",
  async (token) => {
    console.log("toke", token);
    console.log(confirmOpenDoor);

    let { data, errors } = await API.graphql(
      graphqlOperation(confirmOpenDoor, { token })
    );
    return data;
  }
);

export const cancel_order = createAsyncThunk("cancelOrder", async (token) => {
  console.log("toke", token);

  let { data, errors } = await API.graphql(
    graphqlOperation(cancelOrder, { token })
  );
  return data;
});

export const getOrderHistory = createAsyncThunk("getOrderHistory", async () => {
  let { data } = await API.graphql(graphqlOperation(queryOrderHistory));
  return data;
});

const swapOrderSlice = createSlice({
  name: "swapOrder",
  initialState: {
    swapOrder: {
      sharing_point_name: "",
      unique_validation_hash_key: "",
      battery_type: "",
      battery_count: 1,
    },
    mySwapOrder: "",
    isLoading: false,
    message: "",
    orderHistory: [],
    errors: "",
  },
  reducers: {
    setSharingpointName: (state, actions) => {
      state.swapOrder.sharing_point_name = actions.payload;
    },
    setUniqueValidationHashKey: (state, actions) => {
      state.swapOrder.unique_validation_hash_key = actions.payload;
    },
    setBatteryType: (state, actions) => {
      state.swapOrder.battery_type = actions.payload;
    },
    setBatteryCount: (state, actions) => {
      state.swapOrder.battery_count = actions.payload;
    },
    resetSwapOrder : (state, actions)=>{
      state.swapOrder.sharing_point_name=""
      state.swapOrder.unique_validation_hash_key=""
      state.swapOrder.battery_type=""
      state.swapOrder.battery_count=1
    }
  },
  extraReducers: {
    [createOrder.fulfilled]: (state, actions) => {
      state.isLoading = false;
      if(actions.payload.order.success){
        navigate(`/order?token=${actions.payload.order.data.validation_hash_key}`);
      }
    },
    [createOrder.rejected]: (state, actions) => {
      state.isLoading = false;
      state.message = "something went wrong. Please try again later.";
    },
    [createOrder.pending]: (state, actions) => {
      state.isLoading = true;
    },
    [fetchSwapOrderBySpName.pending]: (state, actions) => {
      state.isLoading = true;
    },
    [fetchSwapOrderBySpName.fulfilled]: (state, actions) => {
      console.log(actions);
      if (actions.payload.order.code == "200") {
        state.mySwapOrder = actions.payload.order.data;
      } else {
        state.error = actions.payload.order.message;
        state.mySwapOrder = actions.payload.order.data;
      }
      state.isLoading = false;
    },
    [fetchSwapOrderBySpName.rejected]: (state, actions) => {
      console.log(actions);
      state.isLoading = false;
    },
    [confirmOpenDoor.pending]: (state, actions) => {
      state.isLoading = true;
    },
    [confirmOpenDoor.fulfilled]: (state, actions) => {
      console.log(actions.payload);
      state.isLoading = false;
    },
    [confirmOpenDoor.rejected]: (state, actions) => {
      state.isLoading = false;
    },
    [sub2SwapStateNotification.fulfilled]: (state, actions) => {
      console.log(actions);
    },
    [sub2SwapStateNotification.rejected]: (state, actions) => {
      console.log(actions);
    },
    [getOrderHistory.pending]: (state, actions) => {
      state.isLoading = true;
    },
    [getOrderHistory.fulfilled]: (state, actions) => {
      state.isLoading = false;
      state.orderHistory = actions.payload.history.data || [];
    },
    [getOrderHistory.rejected]: (state, actions) => {
      state.isLoading = false;
    },
  },
});

export const {
  setSharingpointName,
  setUniqueValidationHashKey,
  setBatteryCount,
  setBatteryType,
} = swapOrderSlice.actions;
export default swapOrderSlice.reducer;
