import React from "react";
import { Message } from "semantic-ui-react";
import { useSelector } from "react-redux";

export default () => {
  const { message, statusCode } = useSelector(state => state.error);

  return (
    <>
      {message && (
        <Message
          negative
          style={{
            position: "fixed",
            zIndex: 99999999,
            width: "100%",
            margin: "auto"
          }}
        >
          <Message.Header>Something went wrong</Message.Header>
          <p>{message}</p>
        </Message>
      )}
    </>
  );
};
