import React from "react";
import { Grid, GridColumn, Button } from "semantic-ui-react";

export default ({ selectedSharingpoint }) => {
  return (
    <Grid style={{ padding: "20px 20px 0px 20px" }}>
      <GridColumn textAlign="left" width="4">
        <strong>Street</strong>
      </GridColumn>

      <GridColumn textAlign="right" width="12">
        <strong>
          {selectedSharingpoint.street_address}{" "}
          {selectedSharingpoint.street_number}
        </strong>
      </GridColumn>
      <GridColumn textAlign="left" width="4">
        <strong>City</strong>
      </GridColumn>

      <GridColumn textAlign="right" width="12">
        <strong>
          {selectedSharingpoint.zipcode + " " + selectedSharingpoint.city}
        </strong>
      </GridColumn>
      {selectedSharingpoint.w3w && (
        <>
          <GridColumn textAlign="left" width="4">
            <strong>W3w</strong>
          </GridColumn>

          <GridColumn textAlign="right" width="12">
            <strong>{selectedSharingpoint.w3w}</strong>
          </GridColumn>
        </>
      )}

      <GridColumn textAlign="left" width="4">
        <strong>Navigation</strong>
      </GridColumn>

      <GridColumn textAlign="right" width="12">
        <Button
          circular
          style={{ fontColor: "white", color: "white" }}
          icon="map marker alternate"
          content={`Navigate (${Math.round(
            selectedSharingpoint.distance,
            3
          )}Km)`}
          size="mini"
          onClick={() => {
            const url =
              "https://www.google.de/maps/place/" +
              selectedSharingpoint.street_address +
              "+" +
              +selectedSharingpoint.street_number +
              "," +
              "+" +
              selectedSharingpoint.zipcode +
              "+" +
              selectedSharingpoint.city +
              "/@" +
              selectedSharingpoint.latitude +
              "," +
              selectedSharingpoint.longitude;
            window.location.href = url;
          }}
        />
      </GridColumn>
    </Grid>
  );
};
