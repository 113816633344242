import React from "react";
import { Message, GridColumn, Button } from "semantic-ui-react";

export default ({ text }) => {
  return (
    <>
      <GridColumn width="16">
        <Message style={{ width: "100%" }} info>
          {text}
        </Message>
      </GridColumn>
      <GridColumn width="16" style={{ padding: 0 }}>
        <Button
          onClick={() => window.open("tel:+4930587047030")}
          fluid
          positive
          circular
          icon="call square"
          size="huge"
          content="CALL HOTLINE"
        />
      </GridColumn>
    </>
  );
};
