import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { openDoors } from "../graphql/mutations";
import { setError } from "./errorSlice";
import { navigate } from "@reach/router";
import { closeSwapModal } from "./appState";



export const openModuleDoor = createAsyncThunk(
  "openModuleDoor",
  async (payload , { dispatch }) => {
    try {
      console.log(payload);
      let { data } = await API.graphql(
        graphqlOperation(openDoors, payload)
      );
      const response=data.openDoors.data
      const {code, message}=data.openDoors   
      console.log(code, response, message);
      
      if (code === "200"){
          navigate(`/order?token=${payload.token}`)
          return response
      }else{
          dispatch(setError({message}))
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(closeSwapModal());
  }
  
);

const commandSlice = createSlice({
  name: "commands",
  initialState: {
   
    error: "",
    openDoorIsLoading: false
  },
  reducers: {
   
  },
  extraReducers: {
    

    [openModuleDoor.pending]: (state, actions) => {
      state.openDoorIsLoading= true;
    },
    [openModuleDoor.fulfilled]: (state, actions) => {
      state.openDoorIsLoading= false;
      
   
    },
    [openModuleDoor.rejected]: (state, actions) => {
      state.error = actions;
      state.openDoorIsLoading = false;
    }
  }
});

export default commandSlice.reducer;
