import React, { useEffect } from "react";
import { Grid, Container, GridColumn, Header } from "semantic-ui-react";
import HistoryCard from "../components/HistoryCard";
import { getOrderHistory } from "../store/swapOrderSlice";
import { useSelector, useDispatch, connect } from "react-redux";
import AppLoader from "../components/AppLoader";
import "../assets/styles/history.scss";
import ScreenHeader from "../components/ScreenHeader";

const History = () => {
  const { orderHistory, isLoading } = useSelector(state => state.swapOrder);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrderHistory());
  }, []);

  return (
    <Container className="history-container">
      <ScreenHeader text={"History"}/>
      <Grid style={{ margin: "auto", marginTop: "60px" }}>
        {!isLoading ? (
          orderHistory.map((swap, i) => (
            <GridColumn centered key={i} computer={5} mobile={16}>
              <HistoryCard swap={swap} />
            </GridColumn>
          ))
        ) : (
          <AppLoader marginTop="400px" />
        )}
      </Grid>
    </Container>
  );
};

const mapDispatch = { getOrderHistory };
export default connect(null, mapDispatch)(History);
