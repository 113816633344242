import React, { useState } from "react";
import {
  Button,
  Form,
  Header,
  Container,
  Grid,
  Label,
  Message,
  List,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";
import "./style.scss";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Auth } from "aws-amplify";
import Verification from "./Verification";
import { navigate } from "@reach/router";

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password_confirmation: "",
};

const validationSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup
    .string()
    .required()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
    ),
  password_confirmation: yup
    .string()
    .required()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
    ),
});

const SignUp = props => {
  const [match, setMatch] = useState(false);
  const [state, setState] = useState({});
  const [email, setEmail] = useState();
  const { handleSubmit, errors, register } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
  const submit = data => {
    let email = data.email;
    if (data.password === data.password_confirmation) {
      setMatch(false);
      Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          name: `${data.first_name} ${data.last_name}`,
          given_name: data.first_name,
          family_name: data.last_name,
        },
      })
        .then(data => {
          setEmail(email);
          setState({ ...state, showSubmissionView: true });
        })
        .catch(error => {
          console.log("error", error);
        });
    } else {
      setMatch(true);
    }
  };

  const onClickHandler = event => {
    event.preventDefault();
    navigate("/");
  };

  return (
    <>
      {!state.showSubmissionView && (
        <Container style={{ marginTop: "100px", padding: "20px" }}>
          <Header style={{ color: "white" }}>Registration</Header>
          {match && (
            <Message
              error
              header="There was some errors with your submission"
              content={
                <List bulleted>
                  {[match && <List.Item>Password do not match</List.Item>]}
                </List>
              }
            />
          )}
          <Form onSubmit={handleSubmit(submit)} style={{ padding: "15px" }}>
            <Grid>
              <Grid.Row>
                <label id="signup-label">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  ref={register}
                />
                {errors.first_name && (
                  <Label pointing basic color="red">
                    This field is required
                  </Label>
                )}
              </Grid.Row>
              <Grid.Row>
                <label id="signup-label">Last Name</label>
                <input
                  //onChange={handleOnChange}
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  ref={register}
                />
                {errors.last_name && (
                  <Label pointing basic color="red">
                    This field is required
                  </Label>
                )}
              </Grid.Row>
              <Grid.Row>
                <label id="signup-label">
                  Username and Confirmation's Email{" "}
                </label>
                <input
                  //nChange={handleOnChange}
                  type="email"
                  name="email"
                  placeholder="Email"
                  ref={register}
                />
                {errors.email && (
                  <Label pointing basic color="red">
                    This field is required
                  </Label>
                )}
              </Grid.Row>
              <Grid.Row>
                <label id="signup-label">Password</label>
                <input
                  //onChange={handleOnChange}
                  name="password"
                  type="password"
                  placeholder="Password"
                  ref={register}
                />
                {errors.password && (
                  <Label pointing basic color="red">
                    Must Contain 8 Characters, One Uppercase, One Lowercase, One
                    Number and one special case Character
                  </Label>
                )}
              </Grid.Row>
              <Grid.Row>
                <label id="signup-label">Password confirmation</label>
                <input
                  //onChange={handleOnChange}
                  type="password"
                  name="password_confirmation"
                  placeholder="Password confirmation"
                  ref={register}
                />
                {errors.password_confirmation && (
                  <Label pointing basic color="red">
                    Must Contain 8 Characters, One Uppercase, One Lowercase, One
                    Number and one special case Character
                  </Label>
                )}
              </Grid.Row>
              <Button
                primary
                type="submit"
                style={{ color: "white", marginTop: "60px" }}
                fluid
              >
                REGISTER
              </Button>
              <Button
                onClick={onClickHandler}
                negative
                type="submit"
                style={{ color: "white", marginTop: "20px" }}
                fluid
              >
                CANCEL
              </Button>
            </Grid>
          </Form>
        </Container>
      )}
      {state.showSubmissionView && <Verification email={email} />}
    </>
  );
};

export default SignUp;
