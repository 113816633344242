import React from "react";
import { slide as Menu } from "react-burger-menu";
import ProfileAvatar from "./ProfileAvatar";
import "../Burgermenu.scss";
import { Icon, Divider } from "semantic-ui-react";
import { Link } from "@reach/router";
import { connect, useSelector, useDispatch } from "react-redux";
import { logout } from "../store/loginSlice";
import { hideBurgerMenu, showBurgerMenu } from "../store/appState";
import credentials from "../aws-exports/aws-exports-rel";

const BurgerMenu = props => {
  const dispatch = useDispatch();
  const { username } = useSelector(state => state.login);
  const { isOpenBurgerMenu } = useSelector(state => state.appState);

  const handleBurgerMenu = state => {
    if (state) {
      state.isOpen && !isOpenBurgerMenu
        ? dispatch(showBurgerMenu())
        : dispatch(hideBurgerMenu());
    }
  };

  return (
    <Menu
      {...props}
      onStateChange={handleBurgerMenu}
      isOpen={isOpenBurgerMenu}
      left
    >
      <ProfileAvatar username={username} />

      <Divider style={{ color: "white" }} />

      <Link
        className="menu-item"
        onClick={handleBurgerMenu}
        to="/sharingpoints"
      >
        <Icon size="large" name="lightning"></Icon>
        <strong className="burger-menu-font">Sharingpoints</strong>
      </Link>

      {localStorage.getItem(
        `CognitoIdentityServiceProvider.${credentials.aws_user_pools_web_client_id}.LastAuthUser`
      ) !== process.env.REACT_APP_USER_NAME && (
        <Link className="menu-item" onClick={handleBurgerMenu} to="/orders">
          <Icon size="large" name="history"></Icon>
          <strong className="burger-menu-font">History</strong>
        </Link>
      )}

      <Link onClick={handleBurgerMenu} className="menu-item" to="/">
        <Icon size="large" name="point"></Icon>
        <strong className="burger-menu-font">Map</strong>
      </Link>

      {localStorage.getItem(
        `CognitoIdentityServiceProvider.${credentials.aws_user_pools_web_client_id}.LastAuthUser`
      ) !== process.env.REACT_APP_USER_NAME && (
        <Link to="/settings" onClick={handleBurgerMenu} className="menu-item">
          <Icon size="large" name="setting"></Icon>
          <strong className="burger-menu-font">Settings</strong>
        </Link>
      )}
      <Divider />

      {localStorage.getItem(
        `CognitoIdentityServiceProvider.${credentials.aws_user_pools_web_client_id}.LastAuthUser`
      ) !== process.env.REACT_APP_USER_NAME ? (
        <div
          onClick={() => {
            dispatch(logout());
            handleBurgerMenu();
            window.location = "/";
          }}
        >
          <Icon size="large" name="sign-out"></Icon>
          <strong className="burger-menu-font">logout</strong>
        </div>
      ) : (
        <Link onClick={() => dispatch(hideBurgerMenu())} to="/login">
          <Icon size="large" name="sign-in"></Icon>
          <strong className="burger-menu-font">login</strong>
        </Link>
      )}
    </Menu>
  );
};
const mapDispatch = { logout, hideBurgerMenu, showBurgerMenu };
export default connect(null, mapDispatch)(BurgerMenu);
