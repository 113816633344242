import React, { useState } from "react";
import {
  Button,
  Form,
  Header,
  Message,
  Icon,
  Grid,
  Container,
  Label,
} from "semantic-ui-react";
import "./style.scss";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { navigate } from "@reach/router";

const defaultValues = {
  code: "",
  new_password: "",
};

const validationSchema = yup.object().shape({
  code: yup.string().required(),
  new_password: yup
    .string()
    .required()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
    ),
});

const SignUp = ({ email }) => {
  const [state, setstate] = useState({});
  const { handleSubmit, errors, register } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const submit = data => {
    Auth.forgotPasswordSubmit(email, data.code, data.new_password)
      .then(data => {
        setstate({ ...state, success: true, isLoading: false });
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      })
      .catch(err => {
        setstate({ ...state, success: false, isLoading: true });
      });
  };

  const onClickHandler = event => {
    event.preventDefault();
    navigate("/");
  };

  return (
    <Container style={{ marginTop: "100px", padding: "35px" }}>
      <Form onSubmit={handleSubmit(submit)}>
        <Grid>
          <Grid.Row>
            <Header style={{ color: "white" }}>Change Password</Header>
          </Grid.Row>
          {!state.isLoading && state.success && (
            <Grid.Row>
              <Message positive>
                <Icon name="check circle" /> Password Successfully Changed
              </Message>
            </Grid.Row>
          )}
          {state.isLoading && !state.success && (
            <Grid.Row>
              <Message negative>The code is not correct</Message>
            </Grid.Row>
          )}
          <Grid.Row>
            <label id="signup-label">Email</label>
          </Grid.Row>
          <Grid.Row>
            <input type="email" disabled value={email} />
          </Grid.Row>
          <Grid.Row>
            <label id="signup-label">Code</label>
          </Grid.Row>
          <Grid.Row>
            <input type="text" name="code" placeholder="Code" ref={register} />
            {errors.code && (
              <Label pointing basic color="red">
                This field is required
              </Label>
            )}
          </Grid.Row>
          <Grid.Row>
            <label id="signup-label">new password</label>
          </Grid.Row>
          <Grid.Row>
            <input
              name="new_password"
              placeholder="New password"
              type="password"
              ref={register}
            />
            {errors.new_password && (
              <Label pointing basic color="red">
                Must Contain 8 Characters, One Uppercase, One Lowercase, One
                Number and one special case Character
              </Label>
            )}
          </Grid.Row>
          <Button
            primary
            type="submit"
            style={{ color: "white", marginTop: "60px" }}
            fluid
          >
            SUBMIT
          </Button>
          <Button
            onClick={onClickHandler}
            negative
            type="submit"
            style={{ color: "white", marginTop: "20px" }}
            fluid
          >
            CANCEL
          </Button>
        </Grid>
      </Form>
    </Container>
  );
};

export default SignUp;
