import { GridColumn, Grid, Image } from "semantic-ui-react";
import React from "react";
import { Link } from "@reach/router";

export default () => {
  return (
    <div style={{ margin: "auto", position: "fixed", zIndex: "999" }}>
      <Grid style={{ margin: "auto" }}>
        <GridColumn mobile={3}>
          <div
            style={{
              zIndex: "999",
              position: "fixed",
              width: "35px",
              height: "35px",
              left: "13px",
              top: "13px",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: "20px",
              boxShadow:
                "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)"
            }}
          >
            <Link className="logo" to="/">
              <Image
                style={{
                  position: "fixed",
                  width: "35px",
                  height: "35px",
                  left: "15px",
                  top: "15px",
                  margin: "auto"
                }}
                src="https://swobbee.de/wp-content/uploads/2019/03/benefit3-08.png"
              />
            </Link>
          </div>
        </GridColumn>

        <GridColumn mobile={3}>
          <div
            style={{
              zIndex: "999",
              position: "fixed",
              width: "35px",
              height: "35px",
              right: "13px",
              top: "13px",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: "20px",
              boxShadow:
                "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)"
            }}
          ></div>
        </GridColumn>
      </Grid>
    </div>
  );
};
