import React from "react";
import { Image, Grid, GridColumn, GridRow, Card } from "semantic-ui-react";
import credentials from "../aws-exports/aws-exports-rel";

export default ({ username }) => {
  return (
    <Grid style={{ padding: 20 }} verticalAlign="middle">
      {localStorage.getItem(
        `CognitoIdentityServiceProvider.${credentials.aws_user_pools_web_client_id}.LastAuthUser`
      ) !== process.env.REACT_APP_USER_NAME && (
        <GridRow>
          <GridColumn mobile={5}>
            <Image
              circular
              src={`https://eu.ui-avatars.com/api/?name=${username}&background=eee`}
            />
          </GridColumn>
          <GridColumn
            textAlign="left"
            style={{ fontSize: "20px", color: "white" }}
            mobile={11}
          >
            <strong>{username.split("@")[0]}</strong>
          </GridColumn>
        </GridRow>
      )}

      {!localStorage.getItem(
        `CognitoIdentityServiceProvider.${credentials.aws_user_pools_web_client_id}.LastAuthUser`
      ) ||
        (localStorage.getItem(
          `CognitoIdentityServiceProvider.${credentials.aws_user_pools_web_client_id}.LastAuthUser`
        ) === process.env.REACT_APP_USER_NAME && (
          <GridRow textAlign="left">
            <Card>
              <Card.Content>
                <Card.Header>Hi Swobbee user</Card.Header>

                <Card.Description>
                  Please login and start swapping batteries
                </Card.Description>
              </Card.Content>
            </Card>
          </GridRow>
        ))}
    </Grid>
  );
};
