import React from "react";
import {
  Card,
  Grid,
  Label,
  GridRow,
  GridColumn,
  ListItem,
  List
} from "semantic-ui-react";
import { selectSharingpoint } from "../store/sharingpointSlice";
import { connect, useDispatch } from "react-redux";
import _ , {upperFirst}from "lodash";

const SharingPointCard = ({ sharingpoint }) => {
  const dispatch = useDispatch();
  return (
    <Card
      onClick={() => dispatch(selectSharingpoint(sharingpoint))}
      style={{ width: "95%", margin: "auto" }}
    >
      <Card.Header style={{ padding: "10px" }}>
        <strong style={{ color: "black" }}>{sharingpoint.nick_name}</strong>

        <strong style={{ float: "right" }}>
          <Label circular color="black">
            {sharingpoint.sharing_point_name}
          </Label>{" "}
          <Label color="blue" circular>
            {sharingpoint.sp_type}
          </Label>
          <Label
            color={sharingpoint.sharing_point_status ? "green" : "red"}
            circular
          >
            {sharingpoint.sharing_point_status ? "online" : "offline"}
          </Label>
        </strong>
      </Card.Header>
      <Card.Content style={{ padding: "20px" }}>
        <Grid>
          <GridRow mobile={16} verticalAlign="middle">
            <GridColumn verticalAlign="middle" mobile={16}>
              <GridRow verticalAlign="middle">
                <GridColumn style={{ marginTop: "10px" }} mobile={16}>
                  <span style={{ color: "black" }}>
                    <span title="street">
                      <List>
                        <ListItem
                          icon="point"
                          content={
                            <span style={{marginLeft: "10px"}}>
                              {`${sharingpoint.street_address} ${sharingpoint.street_number}, ${sharingpoint.zipcode} ${sharingpoint.city}`}
                            </span>
                          }
                        />
                        {sharingpoint.sp_bat_ready_info && (
                          <>
                            {Object.keys(
                              JSON.parse(sharingpoint.sp_bat_ready_info)
                            ).sort().map((item, index) => (
                              <ListItem
                                icon={index === 0 ? "battery full" : ""}
                                content={
                                  <span
                                    style={
                                      index !== 0 ? { marginLeft: "22px" } : {marginLeft: "5px"}
                                    }
                                  >
                                    {upperFirst(item.replaceAll("_", " "))} x{" "}
                                    {
                                      JSON.parse(
                                        sharingpoint.sp_bat_ready_info
                                      )[item]
                                    }
                                    {!_.isEmpty(
                                      JSON.parse(
                                        sharingpoint.sp_bat_inside_info
                                      )
                                    ) &&
                                      "/" +
                                        JSON.parse(
                                          sharingpoint.sp_bat_inside_info
                                        )[item]}
                                  </span>
                                }
                              />
                            ))}
                          </>
                        )}

                        <ListItem
                          icon="bicycle"
                          content={
                            <span style={{marginLeft: "5px"}}>
                              {Math.round(sharingpoint.distance, 3)} KILOMETER
                              AWAY
                            </span>
                          }
                        />
                        {sharingpoint.force_to_swap_allowed && (
                          <ListItem
                            icon="info"
                            content={
                              <span style={{marginLeft: "10px"}}>
                                <Label
                                  circular
                                  content={"force to swap allowed"}
                                  color="green"
                                  size="mini"
                                />
                              </span>
                            }
                          />
                        )}
                      </List>
                    </span>
                  </span>
                </GridColumn>
              </GridRow>
            </GridColumn>
          </GridRow>
        </Grid>
      </Card.Content>
    </Card>
  );
};

const mapDispatch = { SharingPointCard };

export default connect(null, mapDispatch)(SharingPointCard);
