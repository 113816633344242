import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

import awsconfig from "../aws-exports/aws-exports-rel";

import Amplify from "aws-amplify";

Amplify.configure(awsconfig);

export const login = createAsyncThunk(
  "login",
  async ({ username, password }, { dispatch }) => {
    try {
      const response = await Auth.signIn({
        username: username,
        password: password,
        clientId: awsconfig.aws_user_pools_web_client_id
      });
      let { email } = response.attributes;
      let uname = response.username;
      window.location="/";
      return {
        username: uname,
        email
      };
    } catch (error) {
      throw error;
    }
  }
);

export const checkSession = createAsyncThunk(
  "checkSession",
  async (_, { dispatch }) => {
    try {
      const { idToken } = await Auth.currentSession();
      return idToken.payload;
    } catch (error) {
      throw error;
    }
  }
);

export const logout = createAsyncThunk("logout", async () => {
  try {
    await Auth.signOut();
    localStorage.clear();
    return;
  } catch (error) {
    throw error;
  }
});

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoggedIn: false,
    username: "",
    userEmail: "",
    isLoading: false,
    error: ""
  },
  extraReducers: {
    [login.fulfilled]: (state, actions) => {
      if (actions.payload.username === process.env.REACT_APP_USER_NAME)
        state.isLoggedIn = true;
      state.isLoading = false;
      state.username = actions.payload.username;
      state.userEmail = actions.payload.userEmail;
      state.token = actions.payload.jwtToken;
    },
    [login.rejected]: (state, actions) => {
      state.isLoading = false;
      state.error = "incorect username or password.";
    },
    [login.pending]: (state, actions) => {
      state.isLoading = true;
    },
    [checkSession.fulfilled]: (state, actions) => {
      state.isLoggedIn = true;

      state.username = actions.payload.name;
      state.userEmail = actions.payload.email;
    },
    [checkSession.rejected]: (state, actions) => {
      state.isLoggedIn = false;
      state.error = actions;
    },
    [logout.fulfilled]: state => {
      state.isLoggedIn = false;
    },
    [logout.rejected]: (state, actions) => {
      state.error = actions.error;
    }
  }
});

export default loginSlice.reducer;
