import { combineReducers } from "redux";
import sharingpointReducer from "../sharingpointSlice";
import loginReducer from "../loginSlice";
import appStateReducer from "../appState";
import swapOrderReducer from "../swapOrderSlice";
import userReducer from "../userSlice";
import validationReducer from "../validationSlice";
import errorReduce from "../errorSlice";
import commandSlice from "../commandSlice";




export default combineReducers({
  sharingpoints: sharingpointReducer,
  login: loginReducer,
  appState: appStateReducer,
  swapOrder: swapOrderReducer,
  user: userReducer,
  validation: validationReducer,
  error:errorReduce,
  commands: commandSlice
});
