let awsmobile = {};

if (process.env.REACT_APP_MODE === "production") {
  awsmobile = {
    aws_appsync_graphqlEndpoint:
      "https://mjjlnaqdmjcmhe55gy7sta6lfa.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_user_pools_id: "eu-central-1_QbKugUzUR",
    aws_user_pools_web_client_id: "1iujo2300pr2tukptq3lvfvmik",
    FLASK_REST_API:
      "https://0wc3wec9se.execute-api.eu-central-1.amazonaws.com/rel_v1",
    "oauth2-signup":
      "https://admin.swobbee.io/oauth2-signup?redirect_url=https://webapp.swobbee.de",
  };
} else if (process.env.REACT_APP_MODE === "beta") {
  awsmobile = {
    aws_appsync_graphqlEndpoint:
      "https://i4zanxgoyvhwbe24pczb6txdhq.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_user_pools_id: "eu-central-1_pO2DYmHFs",
    aws_user_pools_web_client_id: "5ajsmprg9iubmnh0lotr4cn17h",

    FLASK_REST_API:
      "https://xkwq519ksh.execute-api.eu-central-1.amazonaws.com/beta_v1",
    "oauth2-url":
      "https://swobbee-beta.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://webapp-beta.swobbee.io/oauth2-callback&response_type=TOKEN&client_id=5ajsmprg9iubmnh0lotr4cn17h&scope=aws.cognito.signin.user.admin email openid phone profile",
    "oauth2-signup":
      "https://admin-beta.swobbee.io/oauth2-signup?redirect_url=https://webapp-beta.swobbee.io",
  };
} else if (process.env.REACT_APP_MODE === "dev") {
  awsmobile = {
    aws_appsync_graphqlEndpoint:
      "https://2kgmdox2njd3tdg6i7xip2t46u.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_user_pools_id: "eu-central-1_OoM1aKxAJ",
    aws_user_pools_web_client_id: "7qaq5vv1o5mq25cob472n8vg5s",
    FLASK_REST_API:
      "https://ljsmbnvm2h.execute-api.eu-central-1.amazonaws.com/dev",
    "oauth2-signup":
      "https://admin-dev.swobbee.io/oauth2-signup?redirect_url=https://webapp-dev.swobbee.io",
  };
}

export default awsmobile;
