import React, { useEffect, useRef, useState } from "react";
import {
  fetchSharingpoints,
  selectSharingpoint
} from "../store/sharingpointSlice";
import AppLoader from "./AppLoader";
import { setCurrentGeocode } from "../store/userSlice";
import { setSharingpointName } from "../store/swapOrderSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import * as L from "leaflet";
import "./../assets/leaflet.css";

import "../lib/leaflet.locatecontrol";
import "../lib/Leaflet.EdgeMarker";
import "../lib/Center.Control";
import "./../Map.scss";
import ScanQrCodeButton from "./ScanQrCodeButton";

import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { showBatterySelection } from "../store/appState";
import "./../Button.scss";
import "../modal.responsiv.scss";
import Versioning from "./Versioning";
import LoginButton from "./LoginButton";
import axios from "axios";
import {
  Modal,
  ModalContent,
  Grid,
  Header,
  GridColumn,
  Label,
  Button
} from "semantic-ui-react";
import BatteryImage from "./BatteryImage";

const TILE_URL = "https://{s}.tile.osm.org/{z}/{x}/{y}.png";

/** attribution for leaflet */
const ATTRIBUTION =
  'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a>.';

/** remove default icon and set them webpack related */
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("./../assets/images/marker-icon-2x.png"),
  iconUrl: require("./../assets/images/marker-icon.png"),
  shadowUrl: require("./../assets/images/marker-shadow.png")
});

let map = undefined;
let locationLayer = undefined;
let markerLayer = L.markerClusterGroup();

const MainMap = () => {
  const dispatch = useDispatch();
  const [iconHeight, setIconHeight] = useState(49);
  const [iconWidth, setIconWidth] = useState(28);
  const [isOpenPublicModal, setIsPublicModal] = useState();
  const [publicStation, setPublicStation] = useState();

  var spIcon = L.icon({
    iconUrl: require("../assets/images/Icon_active.svg"),
    iconSize: [iconWidth, iconHeight], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [12, 50], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76]
  });

  var spOfflineIcon = L.icon({
    iconUrl: require("../assets/images/Icon_off.svg"),
    iconSize: [iconWidth, iconHeight], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [12, 50], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76]
  });

  const mapRef = useRef(null);
  const [selectedMarker, setSelectedMarker] = useState();
  const { data: sharingPoints, isLoading } = useSelector(
    state => state.sharingpoints
  );

  const [publicSps, setPublicSps] = useState();
  const { longitude, latitude } = useSelector(
    state => state.user.currentGeocode
  );
  const sharingPoint = useSelector(
    state => state.sharingpoints.selectedSharingpoint
  );

  const { isLoggedIn } = useSelector(state => state.login);

  const fetchPublicStations = async () => {
    const { data } = await axios.get(
      "https://0wc3wec9se.execute-api.eu-central-1.amazonaws.com/rel_v1/public_sharingpoints"
    );
    setPublicSps(data.data);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      fetchPublicStations();
      return;
    }
    dispatch(
      fetchSharingpoints({
        latitude: latitude || 52.523882541879,
        longitude: longitude || 13.382704755785,
        radius: 1
      })
    );
  }, [isLoggedIn]);

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (publicSps || sharingPoints.length > 0) {
      drawSharingPoints();
    }
  }, [sharingPoints, publicSps]);

  useEffect(() => {
    if (!sharingPoint) {
      if (selectedMarker && selectedMarker.setIcon) {
        selectedMarker.setIcon(
          sharingPoint.sharing_point_status ? spOfflineIcon : spOfflineIcon
        );
      }
      setSelectedMarker(undefined);
      return;
    }
  }, [sharingPoint]);

  const drawMap = () => {
    map = L.map(mapRef.current, {
      scrollWheelZoom: true,
      minZoom: 6,
      maxZoom: 18,
      zoomControl: false
    });
    let osmLayer = new L.TileLayer(TILE_URL, {
      attribution: ATTRIBUTION
    });
    map.setView(new L.LatLng(latitude, longitude), 8);

    map.addLayer(osmLayer);
    locationLayer = L.featureGroup();
    locationLayer.addTo(map);
    let control = new L.control.LocateControl({
      locateOptions: {
        enableHighAccuracy: true
      },
      keepCurrentZoomLevel: true,
      layer: locationLayer,
      maxZoom: 15
    });
    control.addTo(map);
    // control.start();

    map.on("locationfound", ({ longitude, latitude }) => {
      dispatch(setCurrentGeocode({ longitude, latitude }));
    });
    map.on("locationerror", e => {
      console.log("location position error", e);
    });
  };

  const drawSharingPoints = () => {
    if (!map || !sharingPoints || !publicSps) return;
    if (markerLayer) {
      map.removeLayer(markerLayer);
      markerLayer = undefined;
    }
    if (!markerLayer) {
      markerLayer = L.markerClusterGroup({
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        iconCreateFunction: function(cluster) {
          var markers = cluster.getAllChildMarkers();
          var html = '<div class="circle-leaflet">' + markers.length + "</div>";
          return L.divIcon({
            html: html,
            className: "cluster-leaflet",
            iconSize: L.point(32, 32)
          });
        }
      });
      markerLayer.addTo(map);
    }
    if (isLoggedIn) {
      sharingPoints.forEach(sp => {
        if (sp !== null) {
          let latLng = [sp.latitude, sp.longitude];
          let marker = L.marker(latLng, {
            icon: sp.sharing_point_status ? spIcon : spOfflineIcon,
            spName: sp.sharing_point_name
          }).addTo(markerLayer);

          markerLayer.addLayer(marker);

          marker.on("click", e => {
            if (selectSharingpoint) {
              dispatch(selectSharingpoint(sp));
              dispatch(setSharingpointName(sp.sharing_point_name));
              dispatch(showBatterySelection());
              map.flyTo(latLng);
            }
          });
        }
      });
    } else {
      publicSps.forEach(sp => {
        if (sp !== null) {
          let latLng = [sp.gps_latitude, sp.gps_longitude];
          let marker = L.marker(latLng, {
            icon: sp.is_online ? spIcon : spOfflineIcon,
            spName: sp.sp_serial_number
          }).addTo(markerLayer);

          markerLayer.addLayer(marker);

          marker.on("click", e => {
            setIsPublicModal(true);
            setPublicStation(sp);
            map.flyTo(latLng);
          });
        }
      });
    }
  };

  return (
    <div
      style={{ margin: "0px", padding: "0px", height: "100vH", width: "100%" }}
    >
      <div
        id="map"
        ref={mapRef}
        style={{
          height: "100%",
          width: "100%",
          margin: "0px",
          padding: "0px",
          position: "relative"
        }}
      >
        {isLoading && sharingPoints && <AppLoader />}

        {isLoggedIn && <ScanQrCodeButton />}
        {!isLoggedIn && <LoginButton />}

        <Versioning />
        <Modal centered={false} open={isOpenPublicModal}>
          {publicStation && (
            <ModalContent>
              <Header>
                {publicStation.sp_serial_number}{" "}
                <Label
                  circular
                  style={{ float: "right" }}
                  color={publicStation.is_online ? "green" : "red"}
                  content={publicStation.is_online ? "online" : "offline"}
                />
              </Header>
              <Grid style={{ padding: "20px 20px 0px 20px" }}>
                <GridColumn textAlign="left" width="4">
                  <strong>Street</strong>
                </GridColumn>

                <GridColumn textAlign="right" width="12">
                  <strong>
                    {publicStation.street} {publicStation.street_number}
                  </strong>
                </GridColumn>
                <GridColumn textAlign="left" width="4">
                  <strong>City</strong>
                </GridColumn>

                <GridColumn textAlign="right" width="12">
                  <strong>
                    {publicStation.zipcode + " " + publicStation.city}
                  </strong>
                </GridColumn>
                {publicStation.w3w && (
                  <>
                    <GridColumn textAlign="left" width="4">
                      <strong>W3w</strong>
                    </GridColumn>

                    <GridColumn textAlign="right" width="12">
                      <strong>{publicStation.w3w}</strong>
                    </GridColumn>
                  </>
                )}

                {publicStation.box_status_check &&
                  Object.keys(publicStation.box_status_check).map(
                    (element, i) => (
                      <>
                        {publicStation.box_status_check[element] !== 0 && (
                          <>
                            <GridColumn
                              width="7"
                              key={i}
                              style={{
                                backgroundColor: "#eee",
                                padding: "20px",
                                borderRadius: "15px",
                                margin: "10px",
                                height: 200
                              }}
                            >
                              <Grid textAlign="center" centered>
                                <GridColumn
                                  width="16"
                                  style={{ color: "black" }}
                                  textAlign="center"
                                >
                                  <span
                                    style={{
                                      fontSize: 17,
                                      fontWeight: "bolder"
                                    }}
                                  >
                                    {element
                                      .toUpperCase()
                                      .replaceAll("_", " ")
                                      .substr(0, 9)}
                                  </span>
                                </GridColumn>
                                <GridColumn
                                  width="16"
                                  style={{ color: "black" }}
                                  textAlign="center"
                                >
                                  <BatteryImage
                                    height={60}
                                    width={"auto"}
                                    type={element}
                                  />
                                </GridColumn>
                                <GridColumn
                                  width="16"
                                  style={{
                                    color: "black",
                                    margin: 5,
                                    padding: 0
                                  }}
                                  textAlign="center"
                                >
                                  <Label
                                    style={{ width: "100%" }}
                                    circular
                                    content={
                                      publicStation.box_status_check[element] +
                                      " Ready"
                                    }
                                    color="orange"
                                    size="huge"
                                  />
                                </GridColumn>
                              </Grid>
                            </GridColumn>
                          </>
                        )}
                      </>
                    )
                  )}
                <GridColumn width="16">
                  <Button
                    basic
                    content="Cancel"
                    circular
                    size="huge"
                    onClick={() => setIsPublicModal(false)}
                    fluid
                  />
                </GridColumn>
              </Grid>
            </ModalContent>
          )}
        </Modal>
      </div>
    </div>
  );
};

const mapDispatch = {
  fetchSharingpoints,
  setSharingpointName,
  setCurrentGeocode
};
export default connect(null, mapDispatch)(MainMap);
