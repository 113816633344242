import React, { useState } from "react";
import {
  Button,
  Form,
  Header,
  Message,
  Icon,
  Grid,
  Label,
  Container,
} from "semantic-ui-react";
import "./style.scss";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { navigate } from "@reach/router";

const defaultValues = {
  old_password: "",
  new_password: "",
};

const validationSchema = yup.object().shape({
  old_password: yup
    .string()
    .required()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
    ),
  new_password: yup
    .string()
    .required()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
    ),
});

const FormExampleForm = () => {
  const [state, setstate] = useState({});
  const { handleSubmit, errors, register } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const submit = data => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, data.old_password, data.new_password);
      })
      .then(data => {
        setstate({ ...state, success: true, isLoading: false });
        setTimeout(() => {
          navigate("/");
        }, 3000);
      })
      .catch(err => {
        setstate({ ...state, success: false, isLoading: true });
      });
  };

  const onClickHandler = event => {
    event.preventDefault();
    navigate("/");
  };
  return (
    <Container style={{ marginTop: "100px", padding: "20px" }}>
      <Header style={{ color: "black" }}>Change password</Header>
      {!state.isLoading && state.success && (
        <Message positive>
          <Icon name="check circle" /> Password successfuly changed
        </Message>
      )}
      {state.isLoading && !state.success && (
        <Message negative>Changing password failed</Message>
      )}
      <Form onSubmit={handleSubmit(submit)} style={{ padding: "15px" }}>
        <Grid>
          <Grid.Row>
            <label style={{ color: "black" }} id="signup-label">Old Password</label>
            <input
              name="old_password"
              type="password"
              placeholder="Old password"
              ref={register}
            />
            {errors.old_password && (
              <Label pointing basic color="red">
                Must Contain 8 Characters, One Uppercase, One Lowercase, One
                Number and one special case Character
              </Label>
            )}
          </Grid.Row>
          <Grid.Row>
            <label style={{ color: "black" }} id="signup-label">New Password</label>
            <input
              name="new_password"
              type="password"
              placeholder="New password"
              ref={register}
            />
            {errors.new_password && (
              <Label pointing basic color="red">
                Must Contain 8 Characters, One Uppercase, One Lowercase, One
                Number and one special case Character
              </Label>
            )}
          </Grid.Row>
          <Button
            primary
            type="submit"
            style={{  marginTop: "60px" }}
            fluid
          >
            SUBMIT
          </Button>
          <Button
            onClick={onClickHandler}
            negative
            type="submit"
            style={{  marginTop: "20px" }}
            fluid
          >
            CANCEL
          </Button>
        </Grid>
      </Form>
    </Container>
  );
};

export default FormExampleForm;
