import React from "react";
import { Button } from "semantic-ui-react";
import {
  closeSwapModal,
  showBatterySelection,
  hideBatterySelection,
  hideSetBatteryNumber
} from "../store/appState";
import { useDispatch, connect } from "react-redux";

const CancelSwapButton = () => {
  const dispatch = useDispatch();
  const cancelHandler = () => {
    dispatch(closeSwapModal());
    dispatch(hideBatterySelection());
    dispatch(hideSetBatteryNumber());
  };

  return (
    <Button
      onClick={cancelHandler}
      size="huge"
      basic
      fluid
      style={{  borderRadius: "50px" }}
    >
      CANCEL
    </Button>
  );
};

const mapDispatch = {
  closeSwapModal,
  showBatterySelection,
  hideBatterySelection,
  hideSetBatteryNumber
};
export default connect(null, mapDispatch)(CancelSwapButton);
