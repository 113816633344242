import React from "react";
import { Grid, Label, GridColumn, Message, Button } from "semantic-ui-react";
import BatteryImage from "../components/BatteryImage";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  hideBatterySelection,
  showSetBatteryNumber,
  hideSetBatteryNumber
} from "../store/appState";
import { setBatteryType } from "../store/swapOrderSlice";
import { navigate } from "@reach/router";
import CallSwobbeeHotline from "./CallSwobbeeHotline";
import credentials from "../aws-exports/aws-exports-rel";

const BatteryTypeSelection = ({ batteryInfo, sp_name }) => {
  const {
    sharing_point_status,
    sp_bat_ready_info,
    sharing_point_name,
    sp_bat_inside_info
  } = useSelector(state => state.sharingpoints.selectedSharingpoint);

  const dispatch = useDispatch();

  const handleBatteryTypeButton = type => {
    dispatch(setBatteryType(type));
    dispatch(hideBatterySelection());
    dispatch(showSetBatteryNumber());
  };

  const handleNotLogged = () => {
    dispatch(hideBatterySelection());
    dispatch(hideSetBatteryNumber());
    navigate("/login");
  };

  return (
    <Grid verticalAlign="middle" style={{ margin: "auto", marginTop: "5px" }}>
      {sharing_point_status && (
        <GridColumn width="16">
          <Message
            info
            content="Choose a battery type"
            icon="info"
            header="Step 1"
          />
        </GridColumn>
      )}

      
      {sp_name.startsWith("SP 10") && (
        <CallSwobbeeHotline
          text={
            "For information on battery availability, please call the hotline +49 30587047030"
          }
        />
      )}
      {!sharing_point_status &&
        !sp_name.startsWith("SP 10") &&
        !sp_name.startsWith("SP U30M TEST SIMULATION") && (
          <CallSwobbeeHotline text={"This sharing point is Offline"} />
        )}

      {sp_bat_ready_info &&
        sharing_point_status &&
        !sharing_point_name.startsWith("SP 10") &&
        Object.keys(JSON.parse(sp_bat_inside_info)).map((element, i) => (
          <>
            {JSON.parse(sp_bat_inside_info)[element] !== 0 && (
              <>
                <GridColumn
                  width="7"
                  key={i}
                  style={{
                    backgroundColor: "#eee",
                    padding: "20px",
                    borderRadius: "15px",
                    margin: "10px",
                    height: 250
                  }}
                  onClick={() =>
                    localStorage.getItem(
                      `CognitoIdentityServiceProvider.${credentials.aws_user_pools_web_client_id}.LastAuthUser`
                    ) &&
                    localStorage.getItem(
                      `CognitoIdentityServiceProvider.${credentials.aws_user_pools_web_client_id}.LastAuthUser`
                    ) === process.env.REACT_APP_USER_NAME
                      ? handleNotLogged()
                      : handleBatteryTypeButton(element.replace("_", " "))
                  }
                >
                  <Grid textAlign="center" centered>
                    <GridColumn
                      width="16"
                      style={{ color: "black" }}
                      textAlign="center"
                    >
                      <span style={{ fontSize: 17, fontWeight: "bolder" }}>
                        {element
                          .toUpperCase()
                          .replaceAll("_", " ")
                          .substr(0, 9)}
                      </span>
                    </GridColumn>
                    <GridColumn
                      width="16"
                      style={{ color: "black" }}
                      textAlign="center"
                    >
                      <BatteryImage height={60} width={"auto"} type={element} />
                    </GridColumn>
                    <GridColumn
                      width="16"
                      style={{ color: "black", margin: 5, padding: 0 }}
                      textAlign="center"
                    >
                      <Label
                        style={{ width: "100%" }}
                        circular
                        content={
                          JSON.parse(sp_bat_ready_info)[element] + " Ready"
                        }
                        color="orange"
                        size="huge"
                      />
                    </GridColumn>
                    <GridColumn
                      width="16"
                      style={{ color: "black", margin: 5, padding: 0 }}
                      textAlign="center"
                    >
                      <Label
                        style={{ width: "100%" }}
                        circular
                        content={
                          JSON.parse(sp_bat_inside_info)[element] + " Inside"
                        }
                        color="grey"
                        size="huge"
                      />
                    </GridColumn>
                  </Grid>
                </GridColumn>
              </>
            )}
          </>
        ))}
      
    </Grid>
  );
};

const mapDispatch = { hideBatterySelection, setBatteryType };
export default connect(null, mapDispatch)(BatteryTypeSelection);
